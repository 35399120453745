import { createReducer } from 'redux-create-reducer';
import * as actionTypes from '../actions/actionTypes';

// Note: actual state hierarchy = state => [reducer name, or given name] => whatever is defined below

const initialState = {
  majlisBreakdown: {
    khuddam: [{ majlis: 'Total', fri: 0, sat: 0, sun: 0 }],
    atfal: [{ majlis: 'Total', fri: 0, sat: 0, sun: 0 }],
    ansar: [{ majlis: 'Total', fri: 0, sat: 0, sun: 0 }],
  },
  individualBreakdown: [],
};

export default createReducer(initialState, {
  [actionTypes.IJTEMA_GET_SUMMARY_SUCCESS](state, action) {
    return {
      ...state,
      majlisBreakdown: {
        khuddam: action.payload.majlis_breakdown_khuddam,
        atfal: action.payload.majlis_breakdown_atfal,
        ansar: action.payload.majlis_breakdown_ansar,
      },
    };
  },
  [actionTypes.IJTEMA_GET_MEMBERS_SUCCESS](state, action) {
    return {
      ...state,
      individualBreakdown: action.payload.members,
    };
  },
  [actionTypes.IJTEMA_MARK_AS_PRESENT_SUCCESS](state, action) {
    let newind = state.individualBreakdown.findIndex((ind) => ind.ijtema_id === action.payload.ijtema_id);
    state.individualBreakdown[newind][action.payload.day.toLowerCase()] = true;
    return JSON.parse(JSON.stringify(state));
  },
});
