import { createReducer } from 'redux-create-reducer'
import * as actionTypes from '../actions/actionTypes'

// Note: actual state hierarchy = state => [reducer name, or given name] => whatever is defined below

const initialState = {
  lastUpdated: '2024-02-14T11:09:21.748Z',
  lastFetch: '2024-03-05T13:24:33+04:00',
  officesAndEvents: {
    Atfal: {
      office: 'Atfal',
      restricted: false,
      events: [
        'Class',
        'Follow-up with parents/atfal',
        'Khidmate Khalq',
        'Online Class',
        'Outing',
        'Sports',
        'Sports Camp',
        'Tabligh',
        'Tahrik-e-Jadeed',
        'Tarbiyyat Camp',
        'Waqare Amal',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    "Isha'at": {
      office: "Isha'at",
      restricted: false,
      events: [
        'Magazine',
        'Newsletter',
        'Poster',
        'Social media post',
        'Teaser',
        'Video',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    'Khidmate Khalq': {
      office: 'Khidmate Khalq',
      restricted: false,
      events: [
        'Blood donation',
        'Care For Others',
        'Clothes distribution',
        'Feed a Family',
        'Offered lunch/dinner/meal',
        'Visit elderly/sick',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    Maal: {
      office: 'Maal',
      restricted: false,
      events: [
        'Chanda Collection',
        'Deposit funds in office',
        'Send reminders',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    "Mu'tamad": {
      office: "Mu'tamad",
      restricted: false,
      events: [
        'Convocation',
        'Majlis-e-Amla',
        'Minutes',
        'Office-wise Meeting',
        'Qaedeen Meeting',
        'Wrote letter to Huzoor',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    "Nau Maba'een": {
      office: "Nau Maba'een",
      restricted: false,
      events: [
        'Class',
        'Meeting',
        'Visit new convert',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    'Omoor-e-Talaba': {
      office: 'Omoor-e-Talaba',
      restricted: false,
      events: [
        'Contact Students',
        'Contact Teachers',
        'Meeting',
        'Tuition & Class',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    "San'at-o-Tijarat": {
      office: "San'at-o-Tijarat",
      restricted: false,
      events: ['Other'],
    },
    'Sihat-e-Jismani': {
      office: 'Sihat-e-Jismani',
      restricted: false,
      events: [
        'Babyfoot',
        'Camping',
        'Fitness class',
        'Football',
        'Fun games',
        'Hiking',
        'Indoor games',
        'Outing',
        'Pool/Billard',
        'Swimming',
        'Table Tennis',
        'Treasure Hunt',
        'Video games',
        'Volleyball',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    "Ta'leem": {
      office: "Ta'leem",
      restricted: false,
      events: [
        'Achievement',
        'Activity',
        'Class',
        'Exams',
        'Khuddam Contact',
        'Meeting',
        'Namaaz Class',
        'One-to-One Namaaz Class',
        'One-to-One Quraan Class',
        'Prepared and shared extract',
        'Quraan Class',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    Tabligh: {
      office: 'Tabligh',
      restricted: false,
      events: [
        'Bookstall',
        'Class',
        'Conference',
        'Contact Follow up',
        'DaeenAllah meeting',
        'Door-to-door',
        'Exhibition',
        'Pamphlet distribution',
        'Social media post',
        'Tabligh through media',
        'Waqfe-arzi',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    'Tahrik-e-Jadeed': {
      office: 'Tahrik-e-Jadeed',
      restricted: false,
      events: [
        'Collection of contribution',
        'Collection of forms',
        'Contact khuddam',
        'Distribution of forms',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    Tajneed: {
      office: 'Tajneed',
      restricted: false,
      events: [
        'Collection of data',
        'Update database',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    Tarbiyyat: {
      office: 'Tarbiyyat',
      restricted: false,
      events: [
        'Class',
        'Contact khuddam',
        'Khuddam meeting',
        'Namaz Coaches',
        'Tarbiyyat Camp',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    Ummumi: {
      office: 'Ummumi',
      restricted: false,
      events: [
        'Security',
        'Security for Jummah',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
    'Waqare Amal': {
      office: 'Waqare Amal',
      restricted: false,
      events: [
        'Cleaning',
        'Food distribution',
        'Loading/unloading',
        'Setup',
        'Canvassing/Sharing poster & video on WhatsApp',
        'Committee',
        'Other',
        'Miscellaneous/Non-quantifiable work/Cancelled events',
      ],
    },
  },
  offices: [
    'Atfal',
    "Isha'at",
    'Khidmate Khalq',
    'Maal',
    "Mu'tamad",
    "Nau Maba'een",
    'Omoor-e-Talaba',
    'Rishta Nata',
    "San'at-o-Tijarat",
    'Sihat-e-Jismani',
    "Ta'leem",
    'Tabligh',
    'Tahrik-e-Jadeed',
    'Tajneed',
    'Tarbiyyat',
    'Ummumi',
    'Waqare Amal',
    'Muhasib',
    'Muavin Sadr',
    'Muavin Sadr Waqfenou',
    'Muqami',
  ],
  halaqat: {
    'Rose Hill': {
      All: {
        majlis: 'Rose Hill',
        halqa: 'All',
        khuddam: 1,
        atfal: 25,
      },
      'Belle-Rose': {
        majlis: 'Rose Hill',
        halqa: 'Belle-Rose',
        khuddam: 17,
        atfal: 0,
      },
      Centre: {
        majlis: 'Rose Hill',
        halqa: 'Centre',
        khuddam: 34,
        atfal: 0,
      },
      'North-East': {
        majlis: 'Rose Hill',
        halqa: 'North-East',
        khuddam: 16,
        atfal: 0,
      },
      'North-West': {
        majlis: 'Rose Hill',
        halqa: 'North-West',
        khuddam: 27,
        atfal: 0,
      },
    },
    'Quatre Bornes': {
      All: {
        majlis: 'Quatre Bornes',
        halqa: 'All',
        khuddam: 2,
        atfal: 5,
      },
      Centre: {
        majlis: 'Quatre Bornes',
        halqa: 'Centre',
        khuddam: 34,
        atfal: 4,
      },
      East: {
        majlis: 'Quatre Bornes',
        halqa: 'East',
        khuddam: 29,
        atfal: 11,
      },
      West: {
        majlis: 'Quatre Bornes',
        halqa: 'West',
        khuddam: 22,
        atfal: 6,
      },
    },
    Trefles: {
      All: {
        majlis: 'Trefles',
        halqa: 'All',
        khuddam: 0,
        atfal: 4,
      },
      Centre: {
        majlis: 'Trefles',
        halqa: 'Centre',
        khuddam: 12,
        atfal: 2,
      },
      North: {
        majlis: 'Trefles',
        halqa: 'North',
        khuddam: 13,
        atfal: 7,
      },
      West: {
        majlis: 'Trefles',
        halqa: 'West',
        khuddam: 12,
        atfal: 0,
      },
    },
  },
  majalis: {
    Albion: {
      majlis: 'Albion',
      local_amla_members: 7,
      khuddam: 12,
      atfal: 0,
      last_updated: '2021-11-30',
      short_code: 'ALB',
      local_amla_members_calc: 1,
      '': '',
    },
    Cassis: {
      majlis: 'Cassis',
      local_amla_members: 4,
      khuddam: 8,
      atfal: 2,
      last_updated: '2021-11-30',
      short_code: 'CSS',
      local_amla_members_calc: 1,
      '': '',
    },
    Curepipe: {
      majlis: 'Curepipe',
      local_amla_members: 7,
      khuddam: 14,
      atfal: 8,
      last_updated: '2021-11-30',
      short_code: 'CUR',
      local_amla_members_calc: 1,
      '': '',
    },
    Flacq: {
      majlis: 'Flacq',
      local_amla_members: 1,
      khuddam: 6,
      atfal: 2,
      last_updated: '2021-11-30',
      short_code: 'FLQ',
      local_amla_members_calc: 1,
      '': '',
    },
    Gentilly: {
      majlis: 'Gentilly',
      local_amla_members: 12,
      khuddam: 25,
      atfal: 6,
      last_updated: '2021-11-30',
      short_code: 'GNT',
      local_amla_members_calc: 1,
      '': '',
    },
    'Montagne Blanche': {
      majlis: 'Montagne Blanche',
      local_amla_members: 11,
      khuddam: 30,
      atfal: 4,
      last_updated: '2021-11-30',
      short_code: 'MTB',
      local_amla_members_calc: 1,
      '': '',
    },
    'Montagne Longue': {
      majlis: 'Montagne Longue',
      local_amla_members: 2,
      khuddam: 3,
      atfal: 0,
      last_updated: '2021-11-30',
      short_code: 'MTL',
      local_amla_members_calc: 2,
      '': '',
    },
    'New Grove': {
      majlis: 'New Grove',
      local_amla_members: 5,
      khuddam: 9,
      atfal: 4,
      last_updated: '2021-11-30',
      short_code: 'NGV',
      local_amla_members_calc: 1,
      '': '',
    },
    Pailles: {
      majlis: 'Pailles',
      local_amla_members: 6,
      khuddam: 31,
      atfal: 2,
      last_updated: '2021-11-30',
      short_code: 'PTL',
      local_amla_members_calc: 1,
      '': '',
    },
    Phoenix: {
      majlis: 'Phoenix',
      local_amla_members: 1,
      khuddam: 40,
      atfal: 4,
      last_updated: '2021-11-30',
      short_code: 'PHX',
      local_amla_members_calc: 1,
      '': '',
    },
    'Quartier Militaire': {
      majlis: 'Quartier Militaire',
      local_amla_members: 1,
      khuddam: 9,
      atfal: 6,
      last_updated: '2021-11-30',
      short_code: 'QML',
      local_amla_members_calc: 1,
      '': '',
    },
    'Quatre Bornes': {
      majlis: 'Quatre Bornes',
      local_amla_members: 22,
      khuddam: 87,
      atfal: 26,
      last_updated: '2021-11-30',
      short_code: 'QBR',
      local_amla_members_calc: 2,
      '': '',
    },
    'Rose Hill': {
      majlis: 'Rose Hill',
      local_amla_members: 14,
      khuddam: 95,
      atfal: 25,
      last_updated: '2021-11-30',
      short_code: 'RHL',
      local_amla_members_calc: 1,
      '': '',
    },
    'Saint Pierre': {
      majlis: 'Saint Pierre',
      local_amla_members: 13,
      khuddam: 35,
      atfal: 9,
      last_updated: '2021-11-30',
      short_code: 'STP',
      local_amla_members_calc: 1,
      '': 0.45652173913043476,
    },
    Stanley: {
      majlis: 'Stanley',
      local_amla_members: 10,
      khuddam: 20,
      atfal: 8,
      last_updated: '2021-11-30',
      short_code: 'STN',
      local_amla_members_calc: 1,
      '': '',
    },
    Trefles: {
      majlis: 'Trefles',
      local_amla_members: 1,
      khuddam: 37,
      atfal: 13,
      last_updated: '2021-11-30',
      short_code: 'TRF',
      local_amla_members_calc: 1,
      '': '',
    },
    Triolet: {
      majlis: 'Triolet',
      local_amla_members: 8,
      khuddam: 20,
      atfal: 9,
      last_updated: '2021-11-30',
      short_code: 'TLT',
      local_amla_members_calc: -1,
      '': '',
    },
    All: {
      majlis: 'All',
      local_amla_members: 0,
      khuddam: 481,
      atfal: 128,
      last_updated: '2021-11-30',
      short_code: 'ALL',
      local_amla_members_calc: '',
      '': '',
    },
  },
  officeBearers: [
    {
      username: 'sadrmka',
      name: 'Noormohammad Toraubally',
      accessLevel: 'Admin',
      majlis: 'All',
      office: 'All',
      designation: 'Sadr MKA',
      email: 'mkua.sadr.mu@gmail.com',
      phone_number: '+23052562370',
      whatsapp_number: '',
      hide_see_all_n_r: 'Y',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': 1,
    },
    {
      username: 'umars',
      name: 'Umar A. Sooltangos',
      accessLevel: 'Admin',
      majlis: 'All',
      office: 'All',
      designation: 'Naib Sadr',
      email: 'umar.sooltangos@gmail.com',
      phone_number: '+23054779200',
      whatsapp_number: '',
      hide_see_all_n_r: 'Y',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': 3,
    },
    {
      username: 'ubeidja',
      name: 'Ubeidullah Jamal Ahmad',
      accessLevel: 'Admin',
      majlis: 'Saint Pierre',
      office: 'Muavin Sadr',
      designation: 'Muavin Sadr',
      email: 'ubeidjamal@gmail.com',
      phone_number: '+23059253584',
      whatsapp_number: '',
      hide_see_all_n_r: 'Y',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': 29,
    },
    {
      username: 'shameerr',
      name: 'Shameer Ramjeet',
      accessLevel: 'Admin',
      majlis: 'Triolet',
      office: 'Tarbiyyat',
      designation: 'Naib Sadr, Muhtamim Tarbiyyat',
      email: 'shamr84@gmail.com',
      phone_number: '+23057568241',
      whatsapp_number: '',
      hide_see_all_n_r: 'Y',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': 2,
    },
    {
      username: 'nawaza',
      name: 'M. Nawaz Aullybux',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Tarbiyyat',
      designation: 'Naib Muhtamim Tarbiyyat',
      email: 'nawazaullybux@gmail.com',
      phone_number: '+23057449876',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'mateenj',
      name: 'K. Mateen A. Joolfoo',
      accessLevel: 'Admin',
      majlis: 'Stanley',
      office: "Mu'tamad",
      designation: "Mu'tamad",
      email: 'mkajoolfoo@gmail.com',
      phone_number: '+23057326214',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'normant',
      name: 'Norman A. Taujoo',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Sihat-e-Jismani',
      designation: 'Muhtamim Sihat-e-Jismani',
      email: 'ntaujoo@gmail.com',
      phone_number: '+23057626265',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'irfaans',
      name: 'M. Irfaan Sooltangos',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: "Ta'leem",
      designation: "Muhtamim Ta'leem",
      email: 'imsooltan@gmail.com',
      phone_number: '+23058026529',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'tahirhosany',
      name: 'Hosany, Tahir Ahmad',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Sihat-e-Jismani',
      designation: 'Naib Muhtamim Sihat-e-Jismani',
      email: 'thosany1005@gmail.com',
      phone_number: '+23057679105',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'shamsherk',
      name: 'Shamsher Khudurun',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Maal',
      designation: 'Muhtamim Maal',
      email: 'mkua.maal.mu@gmail.com',
      phone_number: '+23057112555',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'fawwazt',
      name: 'Fawwaz A. Toraubally',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Tahrik-e-Jadeed, Maal',
      designation: 'Naib Muhtamim Maal, Muhtamim Tahrik-e-Jadeed',
      email: 'fawwazt@live.com',
      phone_number: '+23057335334',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'zaheerk',
      name: 'Zaheer Khudurun',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Khidmate Khalq',
      designation: 'Muhtamim Khidmate Khalq',
      email: 'zaheer00313@gmail.com',
      phone_number: '+23057421777',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'mohsinr',
      name: 'Mohsin A. Ramjeet',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Khidmate Khalq',
      designation: 'Naib Muhtamim Khidmate Khalq',
      email: 'mosr12@hotmail.com',
      phone_number: '+23058294708',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'ismaelk',
      name: 'Ismael Khudurun',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Atfal',
      designation: 'Muhtamim Atfal',
      email: 'maelkhu@gmail.com',
      phone_number: '+23055075230',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'zaids',
      name: 'Zaïd A. Sooltangos',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Tabligh',
      designation: 'Naib Muhtamim Tabligh',
      email: 'z_sooltan@hotmail.com',
      phone_number: '+23059488065',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'ashfaql',
      name: 'Ashfaq Loolmahamode',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Ummumi',
      designation: 'Muhtamim Ummumi',
      email: 'ashfaq_692@hotmail.com',
      phone_number: '+23059835858',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'ahmadj',
      name: 'Ahmad Jowaheer',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: "Isha'at",
      designation: "Muhtamim Isha'at",
      email: 'ahjowaheer@gmail.com',
      phone_number: '+23054910838',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': 28,
    },
    {
      username: 'noort',
      name: 'Noor M. Taujoo',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: "Isha'at",
      designation: "Naib Muhtamim Isha'at",
      email: 'noortaujoo12345@gmail.com',
      phone_number: '+23059264025',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'shaann',
      name: 'Shaan M. Nobee',
      accessLevel: 'National Amla Member And Qaed',
      majlis: 'Trefles',
      office: 'Tajneed',
      designation: 'Muhtamim Tajneed',
      email: 's.nobee@yahoo.com',
      phone_number: '+23052518816',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'umarn',
      name: 'M. Umar Nabeebaccus',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: "Nau Maba'een",
      designation: "Muhtamim Nau Maba'een",
      email: 'numar.email@gmail.com',
      phone_number: '+23059049414',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'shouaiba',
      name: 'Shouaib Ismail Alleesaib',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Waqare Amal',
      designation: 'Naib Muhtamim Waqare Amal',
      email: 'shouaiballeesaib@gmail.com',
      phone_number: '+23057018919',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'abdelp',
      name: 'M. Abdel Peerbocus',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: "San'at-o-Tijarat",
      designation: "Muhtamim San'at-o-Tijarat",
      email: 'abdel.peerbocus786@gmail.com ',
      phone_number: '+23059163247',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'jamsheedc',
      name: 'M. Jamsheed A. Chitamun',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Rishta Nata',
      designation: 'Muhtamim Rishta Nata',
      email: 'jamsheedahmad1@yahoo.com',
      phone_number: '+23057884782',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'masroorr',
      name: 'Masroor A. Raman',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Muavin Sadr Waqfenou',
      designation: 'Muavin Sadr Waqfenou',
      email: 'mas.ahmad.r@gmail.com',
      phone_number: '+23058075785',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'tahirh',
      name: 'Tahir A. Hoolash',
      accessLevel: 'National Amla Member And Qaed',
      majlis: 'Trefles',
      office: 'Atfal',
      designation: 'Naib Muhtamim Atfal, Qaed Trefles',
      email: 'tahirhoolash@gmail.com',
      phone_number: '+23057571245',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national, qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'tariqn',
      name: 'Tariq A. E. I. Napaul',
      accessLevel: 'National Amla Member And Qaed',
      majlis: 'Gentilly',
      office: 'Tabligh',
      designation: 'Muhtamim Tabligh, Qaed Gentilly',
      email: 'tariqnapaul@gmail.com',
      phone_number: '+23057683594',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national, qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'tamizsj',
      name: 'Tamiz A. Shaik Joomun',
      accessLevel: 'National Amla Member And Qaed',
      majlis: 'Montagne Blanche',
      office: 'Omoor-e-Talaba',
      designation: 'Muhtamim Omoor-e-Talaba, Qaed Montagne Blanche',
      email: 'tamizahmad3@gmail.com ',
      phone_number: '+23058189560',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national, qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'hadeez',
      name: 'W. A. Hadee Zeadally',
      accessLevel: 'National Amla Member And Qaed',
      majlis: 'Saint Pierre',
      office: 'Muhasib',
      designation: 'Muhasib',
      email: 'zeadallyhadee@gmail.com',
      phone_number: '+23058816635',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'ibrahimj',
      name: 'Ibrahim A. Jowaheer',
      accessLevel: 'National Amla Member And Qaed',
      majlis: 'Rose Hill',
      office: 'Muqami',
      designation: 'Muhtamim Muqami, Qaed Rose Hill',
      email: 'ibjowaheer@gmail.com',
      phone_number: '+23057498673',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national, qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'alinain',
      name: 'Ali H. M. Nain',
      accessLevel: 'Qaed',
      majlis: 'Quatre Bornes',
      office: 'All',
      designation: 'Qaed Quatre Bornes',
      email: 'alinaim27@gmail.com',
      phone_number: '+23057111780',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'shakeelm',
      name: 'Shakeel A. Mohun',
      accessLevel: 'Qaed',
      majlis: 'Albion',
      office: 'All',
      designation: 'Qaed Albion',
      email: 'shakmo2406@gmail.com',
      phone_number: '+23059088674',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'labeedg',
      name: 'Labeed Ahmad Golamaully',
      accessLevel: 'Qaed',
      majlis: 'Flacq',
      office: 'All',
      designation: 'Qaed Flacq',
      email: 'labeed_ahmad@live.com',
      phone_number: '+23059216477',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'hashimk',
      name: 'Hashim M. Korimbocus',
      accessLevel: 'Qaed',
      majlis: 'Pailles',
      office: 'All',
      designation: 'Qaed Pailles',
      email: 'hkorimbocus@gmail.com',
      phone_number: '+23058027657',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'jaabirt',
      name: 'Jaabir A. Taujoo',
      accessLevel: 'Qaed',
      majlis: 'Phoenix',
      office: 'All',
      designation: 'Qaed Phoenix',
      email: 'jaabir.taujoo@gmail.com',
      phone_number: '+23058663871',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'ahsand',
      name: 'Ahsan A. Durgauhee',
      accessLevel: 'Qaed',
      majlis: 'Curepipe',
      office: 'All',
      designation: 'Qaed Curepipe',
      email: 'ahsandurgauhee@gmail.com',
      phone_number: '+23059328300',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'rosehill',
      name: 'Ibrahim A. Jowaheer',
      accessLevel: 'Qaed',
      majlis: 'Rose Hill',
      office: 'All',
      designation: 'Qaed Rose Hill',
      email: 'mkua.qaed.rh.mu@gmail.com',
      phone_number: '+23057498673',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'tariqs',
      name: 'M. Tariq Subratty',
      accessLevel: 'Qaed',
      majlis: 'Stanley',
      office: 'All',
      designation: 'Qaed Stanley',
      email: 'ts_1@live.com',
      phone_number: '+23054931215',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'mouzakirn',
      name: 'Mouzakir Nathire',
      accessLevel: 'Qaed',
      majlis: 'Saint Pierre',
      office: 'All',
      designation: 'Qaed Saint Pierre',
      email: 'ahmadmnathire@gmail.com',
      phone_number: '+23058255039',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'masroorm',
      name: 'Masroor A. Munreddy',
      accessLevel: 'Qaed',
      majlis: 'Quartier Militaire',
      office: 'All',
      designation: 'Qaed Quartier Militaire',
      email: 'munreddymasroor.faran@gmail.com',
      phone_number: '+23057191500',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'hadih',
      name: 'Hadi A. Hoolash',
      accessLevel: 'Qaed',
      majlis: 'Montagne Longue',
      office: 'All',
      designation: 'Qaed Montagne Longue',
      email: 'hadih0101@gmail.com',
      phone_number: '+23059810817',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'rahulj',
      name: 'Rahul U. Jeeneea',
      accessLevel: 'Qaed',
      majlis: 'Triolet',
      office: 'All',
      designation: 'Qaed Triolet',
      email: 'rahuluttam@live.com',
      phone_number: '+23059232894',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'waleedr',
      name: 'Waleed A. Rahimun',
      accessLevel: 'Qaed',
      majlis: 'New Grove',
      office: 'All',
      designation: 'Qaed New Grove',
      email: 'wrahimun1203@gmail.com',
      phone_number: '+23057331340',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'wazeerm',
      name: 'Wazeer-Ud-Din Meerun',
      accessLevel: 'Qaed',
      majlis: 'Cassis',
      office: 'All',
      designation: 'Qaed Cassis',
      email: 'merunwazir@gmail.com',
      phone_number: '+23057966525',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'qaed',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'suleimana',
      name: 'Suleiman H. Atchia',
      accessLevel: 'National Amla Member',
      majlis: 'All',
      office: 'Waqare Amal',
      designation: 'Muhtamim Waqare Amal',
      email: 'suleimanatchia@gmail.com',
      phone_number: '+23058026840',
      whatsapp_number: '',
      hide_see_all_n_r: '',
      ob_group: 'national',
      'hide_Merge status': 'EMAIL_SENT',
      'hide_Column M': '',
    },
    {
      username: 'testnazim',
      name: 'Test Nazim',
      accessLevel: 'Nazim',
      majlis: 'Saint Pierre',
      office: 'Atfal',
      designation: 'Nazim Atfal Saint Pierre',
      email: 'ubeidjamal+testnazim@gmail.com',
      phone_number: 23059253584,
      whatsapp_number: 23059253584,
      hide_see_all_n_r: '',
      ob_group: 'other',
      'hide_Merge status': '',
      'hide_Column M': '',
    },
  ],
  localAmlaMembers: [
    {
      majlis: 'Quatre Bornes',
      designation: 'Naib Qaed',
      name: 'Zaheer Khudurun',
      phone_number: '57421777',
      email: 'zaheer00313@gmail.com',
      office: 'All',
    },
  ],
}

// const initialState = {
//   majalis: {},
//   halaqat: {},
//   offices: [],
//   officeBearers: [],
//   officesAndEvents: {},
//   lastUpdated: "2019-12-20T15:00:00",
//   lastFetch: "2019-12-20T15:00:00",
// };

export default createReducer(initialState, {
  [actionTypes.UPDATE_REQUIRED_LISTS_SUCCESS](state, action) {
    if (action.payload.updated) {
      let officesAndEvents = {}
      let offices = []
      let officeBearers = action.payload.officeBearers
      let localAmlaMembers = action.payload.localAmlaMembers
      let halaqat = {}
      let majalis = {}
      for (var i = 0; i < action.payload.officesAndEvents.length; i++) {
        offices = [...offices, action.payload.officesAndEvents[i].office]
        if (!action.payload.officesAndEvents[i].restricted) {
          let key = action.payload.officesAndEvents[i].office
          officesAndEvents[key] = action.payload.officesAndEvents[i]
        }
      }

      for (var j = 0; j < action.payload.majalis.length; j++) {
        let key = action.payload.majalis[j].majlis
        majalis[key] = action.payload.majalis[j]
      }

      for (var k = 0; k < action.payload.halaqat.length; k++) {
        let majlis = action.payload.halaqat[k].majlis
        let halqa = action.payload.halaqat[k].halqa
        if (halaqat[majlis] === undefined) {
          halaqat[majlis] = {}
        }
        halaqat[majlis][halqa] = action.payload.halaqat[k]
      }
      const newState = {
        lastUpdated: action.payload.lastUpdated,
        lastFetch: action.payload.lastFetch,
        officesAndEvents,
        offices,
        halaqat,
        majalis,
        officeBearers,
        localAmlaMembers,
      }
      console.log(newState)
      return newState
    } else {
      return {
        ...state,
        lastFetch: action.payload.lastFetch,
      }
    }
  },
})
