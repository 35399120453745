import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Layout, Menu, Button, Divider, Row, Col } from 'antd'
import { getUserSession } from '../../constants/stateAccessors'
import { logout } from '../../awsCognitor/awsActions'
import { Swipeable } from 'react-swipeable'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import { Box } from '@mantine/core'

const { Sider, Header } = Layout

const Sidebar = (props) => {
  const userAttributes = useSelector((state) => getUserSession(state).attributes)
  const dispatch = useDispatch()

  let id = window.location.hash.replace('#', '')

  if (id === '/') {
    id = '/spiritual-reform'
  }

  let fullscreenMenuVisble = props.smallScreen && !props.collapsed

  const designations = userAttributes['custom:designation'].split(', ')

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      width={props.width}
      breakpoint="md"
      collapsedWidth="0"
      onBreakpoint={props.onBreakpoint}
      className="bg sider whitewash"
    >
      <Swipeable
        onSwipedLeft={() => {
          if (props.smallScreen) {
            props.toggle()
          }
        }}
        className="h-full"
        trackMouse={true}
      >
        {props.smallScreen && <Header className="text-center">MKA-M Report App</Header>}
        <Row
          style={{
            padding: props.smallScreen ? '8px 0px' : designations.length === 1 ? '21px 0px' : '11px 0px',
            marginTop: props.smallScreen ? '8px' : '0px',
            marginBottom: props.smallScreen ? '16px' : '8px',
          }}
          className="text-offwhite bg-primary-500"
          type="flex"
          justify="center"
          align="middle"
        >
          <Col>
            <div className="text-center">{userAttributes.name}</div>
            <Divider className="bg-offwhite" style={{ margin: '4px 0' }} />
            {designations.map((v, k) => (
              <div className="text-center" key={k}>
                {v}
              </div>
            ))}
          </Col>
        </Row>
        <Menu
          onClick={() => {
            if (props.smallScreen) props.collapse()
          }}
          mode="inline"
          defaultSelectedKeys={[id]}
          style={{
            borderRight: 0,
            background: 'unset',
            textAlign: props.smallScreen ? 'center' : 'left',
            fontSize: props.smallScreen ? '16px' : '14px',
          }}
        >
          <Menu.Item key="/spiritual-reform">
            <Link to="/spiritual-reform" exact={'true'}>
              Spiritual Reform
            </Link>
          </Menu.Item>
          {userAttributes['custom:accessLevel'] !== 'Nazim' && (
            <Menu.Item key="/activities">
              <Link to="/activities" exact={'true'}>
                Activities
              </Link>
            </Menu.Item>
          )}
          {(userAttributes['custom:accessLevel'] === 'Admin' ||
            userAttributes['custom:accessLevel'] === 'Qaed' ||
            userAttributes['custom:accessLevel'].indexOf('National Amla') > -1) && (
            <Menu.Item key="/namaz">
              <Link to="/namaz" exact={'true'}>
                Namaz Baajamaat
              </Link>
            </Menu.Item>
          )}
          {(userAttributes['custom:accessLevel'] === 'Admin' ||
            userAttributes['custom:accessLevel'] === 'Qaed' ||
            userAttributes['custom:accessLevel'].indexOf('National Amla') > -1) && (
            <Menu.Item key="/doortodoor">
              <Link to="/doortodoor" exact={'true'}>
                Door To Door
              </Link>
            </Menu.Item>
          )}
          {/* <Menu.Item key="/atfal-presence">
            <Link to="/atfal-presence" exact={'true'}>
              Atfal Class Presence
            </Link>
          </Menu.Item> */}

          <Menu.Item key="/officebearers">
            <Link to="/officebearers" exact={'true'}>
              Office Bearers
            </Link>
          </Menu.Item>
          {(userAttributes['custom:accessLevel'] === 'Admin' || userAttributes['custom:accessLevel'].indexOf('National Amla') > -1) && (
            <Menu.Item key="/muhtamim">
              <Link to="/muhtamim" exact={'true'}>
                Muhtamim Report
              </Link>
            </Menu.Item>
          )}
          {userAttributes['custom:accessLevel'] === 'Admin' && (
            <Menu.Item key="/admin">
              <Link to="/admin" exact={'true'}>
                Admin
              </Link>
            </Menu.Item>
          )}
          {userAttributes['custom:accessLevel'] !== 'Nazim' && (
            <Box
              component={Menu.Item}
              key="/ijtema"
              sx={(theme) => ({
                '> span > a': {
                  color: '#fff',
                  ':hover': {
                    color: '#fff',
                  },
                },
                background: theme.fn.gradient(),
                '&.ant-menu-item:active': {
                  background: theme.fn.gradient({ from: 'primary', to: 'cyan.4', deg: 90 }),
                },
              })}
            >
              <Box component={Link} to="/ijtema" exact={'true'}>
                National Ijtema
              </Box>
            </Box>
          )}
        </Menu>
        <Button type="primary" style={{ width: '80%', marginLeft: '10%', marginTop: '10%' }} onClick={() => dispatch(logout())}>
          Log Out
        </Button>
        <span
          className={`ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left ${fullscreenMenuVisble ? 'trigger-adjust' : ''}`}
          onClick={props.toggle}
        >
          <MenuUnfoldOutlined />
        </span>
      </Swipeable>
    </Sider>
  )
}

export default Sidebar
