import React from 'react'
import { Layout, Tabs } from 'antd'
import SwipeableTabs from '../SwipeableTabs'
import AtfalPresenceReport from './AtfalPresenceReport'

const AtfalPresence = () => {
  let tabs = []

  tabs.push()

  return (
    <Layout className="h-full">
      <Layout.Header>Atfal Class Presence</Layout.Header>
      <Layout.Content className="px-1 pb-4">
        <SwipeableTabs defaultActiveKey="1">
          <Tabs.TabPane tab="Send Report" key={tabs.length + 1}>
            <AtfalPresenceReport />
          </Tabs.TabPane>
        </SwipeableTabs>
      </Layout.Content>
    </Layout>
  )
}

export default AtfalPresence
