export const UPDATE_REQUIRED_LISTS = 'UPDATE_REQUIRED_LISTS'
export const UPDATE_REQUIRED_LISTS_SUCCESS = 'UPDATE_REQUIRED_LISTS_SUCCESS'

export const SEND_ACTIVITY_REPORT = 'SEND_ACTIVITY_REPORT'
export const SEND_MUHTAMIM_REPORT = 'SEND_MUHTAMIM_REPORT'
export const SEND_INDIVIDUAL_NAMAZ_REPORT = 'SEND_INDIVIDUAL_NAMAZ_REPORT'
export const SEND_LOCAL_AMLA_NAMAZ_REPORT = 'SEND_LOCAL_AMLA_NAMAZ_REPORT'
export const SEND_MAJLIS_NAMAZ_REPORT = 'SEND_MAJLIS_NAMAZ_REPORT'
export const SEND_DOOR_TO_DOOR_REPORT = 'SEND_DOOR_TO_DOOR_REPORT'
export const SEND_SPIRITUAL_REFORM_REPORT = 'SEND_SPIRITUAL_REFORM_REPORT'

export const GET_ACTIVITY_REPORTS = 'GET_ACTIVITY_REPORTS'
export const GET_ACTIVITY_REPORTS_SUCCESS = 'GET_ACTIVITY_REPORTS_SUCCESS'
export const DELETE_ACTIVITY_REPORT = 'DELETE_ACTIVITY_REPORT'
export const DELETE_ACTIVITY_REPORT_SUCCESS = 'DELETE_ACTIVITY_REPORT_SUCCESS'

export const GET_OWN_NAMAZ_REPORTS = 'GET_OWN_NAMAZ_REPORTS'
export const GET_OWN_NAMAZ_REPORTS_SUCCESS = 'GET_OWN_NAMAZ_REPORTS_SUCCESS'
export const DELETE_NAMAZ_REPORT = 'DELETE_NAMAZ_REPORT'
export const DELETE_NAMAZ_REPORT_SUCCESS = 'DELETE_NAMAZ_REPORT_SUCCESS'

export const GET_OWN_MUHTAMIM_REPORTS = 'GET_OWN_MUHTAMIM_REPORTS'
export const GET_OWN_MUHTAMIM_REPORTS_SUCCESS = 'GET_OWN_MUHTAMIM_REPORTS_SUCCESS'
export const DELETE_MUHTAMIM_REPORT = 'DELETE_MUHTAMIM_REPORT'
export const DELETE_MUHTAMIM_REPORT_SUCCESS = 'DELETE_MUHTAMIM_REPORT_SUCCESS'

export const GET_OWN_DOOR_TO_DOOR_REPORTS = 'GET_OWN_DOOR_TO_DOOR_REPORTS'
export const GET_OWN_DOOR_TO_DOOR_REPORTS_SUCCESS = 'GET_OWN_DOOR_TO_DOOR_REPORTS_SUCCESS'
export const DELETE_DOOR_TO_DOOR_REPORT = 'DELETE_DOOR_TO_DOOR_REPORT'
export const DELETE_DOOR_TO_DOOR_REPORT_SUCCESS = 'DELETE_DOOR_TO_DOOR_REPORT_SUCCESS'

export const GET_DOOR_TO_DOOR_SUMMARY = 'GET_DOOR_TO_DOOR_SUMMARY'
export const GET_DOOR_TO_DOOR_SUMMARY_SUCCESS = 'GET_DOOR_TO_DOOR_SUMMARY_SUCCESS'
export const GET_DOOR_TO_DOOR_REPORTS = 'GET_DOOR_TO_DOOR_REPORTS'
export const GET_DOOR_TO_DOOR_REPORTS_SUCCESS = 'GET_DOOR_TO_DOOR_REPORTS_SUCCESS'

export const GET_ALL_MUHTAMIM_REPORTS = 'GET_ALL_MUHTAMIM_REPORTS'
export const GET_ALL_MUHTAMIM_REPORTS_SUCCESS = 'GET_ALL_MUHTAMIM_REPORTS_SUCCESS'

export const GET_ALL_ACTIVITY_REPORTS = 'GET_ALL_ACTIVITY_REPORTS'
export const GET_ALL_ACTIVITY_REPORTS_SUCCESS = 'GET_ALL_ACTIVITY_REPORTS_SUCCESS'

export const GET_INDIVIDUAL_NAMAZ_REPORTS = 'GET_INDIVIDUAL_NAMAZ_REPORTS'
export const GET_INDIVIDUAL_NAMAZ_REPORTS_SUCCESS = 'GET_INDIVIDUAL_NAMAZ_REPORTS_SUCCESS'
export const GET_MAJLIS_NAMAZ_REPORTS = 'GET_MAJLIS_NAMAZ_REPORTS'
export const GET_MAJLIS_NAMAZ_REPORTS_SUCCESS = 'GET_MAJLIS_NAMAZ_REPORTS_SUCCESS'
export const GET_SPIRITUAL_REFORM_REPORTS = 'GET_SPIRITUAL_REFORM_REPORTS'
export const GET_SPIRITUAL_REFORM_REPORTS_SUCCESS = 'GET_SPIRITUAL_REFORM_REPORTS_SUCCESS'

/// IJTEMA
export const IJTEMA_GET_SUMMARY = 'IJTEMA_GET_SUMMARY'
export const IJTEMA_GET_SUMMARY_SUCCESS = 'IJTEMA_GET_SUMMARY_SUCCESS'

export const IJTEMA_GET_MEMBERS = 'IJTEMA_GET_MEMBERS'
export const IJTEMA_GET_MEMBERS_SUCCESS = 'IJTEMA_GET_MEMBERS_SUCCESS'

export const IJTEMA_MARK_AS_PRESENT = 'IJTEMA_MARK_AS_PRESENT'
export const IJTEMA_MARK_AS_PRESENT_SUCCESS = 'IJTEMA_MARK_AS_PRESENT_SUCCESS'
