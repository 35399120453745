import { all, put, takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { loggedIn, loginError, loggedOut, loggingIn, signupError, signUpSuccess, sessionExpired, updateUserSuccess } from './awsActions';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';

import {
  CognitoIdentityProviderClient,
  AdminConfirmSignUpCommand,
  AdminAddUserToGroupCommand,
  AdminUpdateUserAttributesCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { message, notification } from 'antd';

export function* processCreateUser(data) {
  let info = message.loading('Creating user...', 0);

  const { username, password, attributes } = data.payload;

  const req = {
    username,
    password,
    attributes,
  };

  try {
    yield call(
      {
        context: Auth,
        fn: Auth.signUp,
      },
      req
    );

    const userCreds = yield call({
      context: Auth,
      fn: Auth.currentCredentials,
    });

    const cognitoConfig = Auth.configure();
    const cognitoIdPoolRegion = cognitoConfig.region;
    // const AWSconfig = new AWS.Config();
    const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({
      apiVersion: '2016-04-18',
      credentials: userCreds,
      region: cognitoIdPoolRegion,
    });

    let params = {
      UserPoolId: cognitoConfig.userPoolId,
      Username: req.username,
    };

    const confirmSignup = yield call(
      {
        context: cognitoIdentityProviderClient,
        fn: cognitoIdentityProviderClient.send,
      },
      new AdminConfirmSignUpCommand(params)
    );

    console.log('confirm sign up: ', confirmSignup);

    if (attributes['custom:accessLevel'] === 'Admin') {
      params = {
        GroupName: 'Administrators',
        UserPoolId: cognitoConfig.userPoolId,
        Username: req.username,
      };

      const addUserToGroup = yield call(
        {
          context: cognitoIdentityProviderClient,
          fn: cognitoIdentityProviderClient.send,
        },
        new AdminAddUserToGroupCommand(params)
      );
      console.log('Add user to group: ', addUserToGroup);
    }

    data.payload.clear();
    yield put(signUpSuccess(req));
  } catch (err) {
    console.log(err);
    yield put(signupError(err.message));
  }
  info();
}

export function* processUpdateUser(data) {
  let info = message.loading('Updating user...', 0);
  const { username, attributes } = data.payload;

  try {
    const userCreds = yield call({
      context: Auth,
      fn: Auth.currentCredentials,
    });

    const cognitoConfig = Auth.configure();
    const cognitoIdPoolRegion = cognitoConfig.region;

    const cognitoIdentityProviderClient = new CognitoIdentityProviderClient({
      apiVersion: '2016-04-18',
      credentials: userCreds,
      region: cognitoIdPoolRegion,
    });

    let params = {
      UserPoolId: cognitoConfig.userPoolId,
      Username: username,
      UserAttributes: Object.keys(attributes).reduce((res, cur) => [...res, { Value: attributes[cur], Name: cur }], []),
    };

    yield call(
      {
        context: cognitoIdentityProviderClient,
        fn: cognitoIdentityProviderClient.send,
      },
      new AdminUpdateUserAttributesCommand(params)
    );

    data.payload.clear();
    yield put(updateUserSuccess({
      username,
      attributes,
    }));
  } catch (err) {
    console.log(err);
    notification['warning']({
      message: 'Failed to update user',
      duration: 5,
    });
  }
  info();
}

export function* processRefreshLogin() {
  try {
    const currentUser = yield call({
      context: Auth,
      fn: Auth.currentAuthenticatedUser,
    });

    const { username, attributes } = currentUser;

    // // use this function to update custom attributes
    // if (username === 'shameerr') {
    //   console.log('updating ' + username);
    //   const asd = yield call(
    //     {
    //       context: Auth,
    //       fn: Auth.updateUserAttributes,
    //     },
    //     currentUser,
    //     {
    //       // 'custom:designation': 'Naib Sadr, Muhtamim Sihat-e-Jismani',
    //       'custom:majlis': 'All'
    //     }
    //   );
    //   console.log(asd)
    // }

    // const attributesResponse = yield call(
    //   {
    //     context: Auth,
    //     fn: Auth.userAttributes
    //   },
    //   currentUser
    // );

    // let attributes = {};
    // for (let i = 0; i < attributesResponse.length; i++) {
    //   attributes[attributesResponse[i].Name] = attributesResponse[i].Value;
    // }

    yield put(
      loggedIn({
        username,
        attributes,
      })
    );
  } catch (err) {
    yield put(sessionExpired());
  }
}

export function* processLogout(data) {
  // console.log('trying to log out')
  yield call({ context: Auth, fn: Auth.signOut });
  yield put(loggedOut());
}

export function* processLogin(data) {
  const { username, password } = data.payload;

  yield put(loggingIn());

  try {
    const user = yield call(
      {
        context: Auth,
        fn: Auth.signIn,
      },
      username,
      password
    );

    // TODO: cater for this process in app
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      console.log(user.challengeParam);
      // user.challengeParam.userAttributes.name = "Ubeidullah Jamal Ahmad";
      // user.challengeParam.userAttributes.preferred_username = "uja";
      // user.challengeParam.userAttributes["custom:accessLevel"] = "Admin";
      // user.challengeParam.userAttributes["custom:designation"] = "Muavin Sadr";
      // user.challengeParam.userAttributes["custom:office"] = "All";
      // user.challengeParam.userAttributes["custom:majlis"] = "All";
      // delete user.challengeParam.userAttributes.email_verified;
      // delete user.challengeParam.userAttributes.phone_number_verified;

      Auth.completeNewPassword(user, password, user.challengeParam.userAttributes).then(
        (user) => console.log(user),
        (err) => console.log(err)
      );
    }

    // TODO: CAN USE THIS TO LIST CURRENT USERS
    // Auth.currentCredentials().then(userCreds => {
    //   console.log(userCreds);
    //   const cognitoConfig = Auth.configure();
    //   console.log(cognitoConfig);
    //   const cognitoIdPoolRegion = cognitoConfig.region;
    //   const cognitoUserPoolID = cognitoConfig.userPoolId;
    //   const AWSconfig = new AWS.Config({apiVersion: '2016-04-18', credentials: userCreds, region: cognitoIdPoolRegion});
    //   const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider(AWSconfig);
    //
    //   let params = {
    //     UserPoolId: cognitoUserPoolID,
    //     /* required */
    //     Limit: 0
    //   };
    //   cognitoidentityserviceprovider.listUsers(params, function(err, userData) {
    //     if (err) {
    //       console.log('Error in getCognitoUsers: ', err);
    //     } else {
    //       console.log(userData)
    //     }
    //   });
    // }, err => console.log(err));
    //
    const { attributes } = user;

    try {
      const funcName = 'GetBackendURLs';
      const path = '';
      const myInit = {
        body: { body: 'anything' },
      };

      const apiResponse = yield call(
        {
          context: API,
          fn: API.post,
        },
        funcName,
        path,
        myInit
      );

      let backendUrl = JSON.parse(apiResponse.body).url;

      yield put(
        loggedIn({
          username,
          attributes,
          backendUrl,
        })
      );
    } catch (err) {
      console.log(err);
      yield put(loginError({ username, errorMessage: 'Failed to get backend URLs. Please contact admin.' }));
    }
  } catch (err) {
    console.log(err);
    let errorMessage = 'Failed to login.';
    if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      errorMessage = 'User confirmation is incomplete. Contact Admin.';
    } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      errorMessage = 'Password reset is required. Contact Admin.';
    } else if (err.code === 'NotAuthorizedException' || err.code === 'UserNotFoundException') {
      errorMessage = 'Username or password is invalid.';
    }

    yield put(loginError({ username, errorMessage }));
  }
}

export function* processLoginSaga() {
  yield takeEvery(actionTypes.LOGIN, processLogin);
}

export function* processLogoutSaga() {
  yield takeEvery(actionTypes.LOGOUT, processLogout);
}

export function* processCreateUserSaga() {
  yield takeEvery(actionTypes.CREATE_USER, processCreateUser);
}

export function* processRefreshLoginSaga() {
  yield takeEvery(actionTypes.REFRESH_LOGIN, processRefreshLogin);
}

export function* processUpdateUserSaga() {
  yield takeEvery(actionTypes.UPDATE_USER, processUpdateUser);
}

export default function* sessionsSaga() {
  yield all([processLoginSaga(), processLogoutSaga(), processCreateUserSaga(), processRefreshLoginSaga(), processUpdateUserSaga()]);
}
