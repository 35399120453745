import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Layout,
  DatePicker,
  Button,
  Empty,
  List,
  Collapse,
  Checkbox,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getAllActivityReports } from "../../../actions/";
import {
  accessAllActivityReports,
  getMajalis,
} from "../../../constants/stateAccessors";
import dayjs from "dayjs";

const { Content } = Layout;

const ActivitiesViewAllReports = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const activityReports =
    useSelector((state) => accessAllActivityReports(state)) || [];
  const majalis = useSelector((state) => getMajalis(state));
  const [sortByOffice, setSortByOffice] = useState(true);

  const dispatch = useDispatch();

  const asMMYYYY = (date) => date.format("MM-YYYY");

  const fetchReport = (monthYear) => {
    dispatch(getAllActivityReports({ monthYear }));
  };

  const previousMonth = () => {
    setSelectedDate(selectedDate.clone().subtract(1, "months")); // use clone to set a new object -> setState won't know the object change
  };

  const nextMonth = () => {
    if (asMMYYYY(selectedDate) !== asMMYYYY(dayjs())) {
      setSelectedDate(selectedDate.clone().add(1, "months")); // use clone to set a new object -> setState won't know the object change
    }
  };

  let compiledReports = Object.keys(majalis).reduce(
    (reports, value) => {
      let filtered = activityReports[asMMYYYY(selectedDate)]
        ? activityReports[asMMYYYY(selectedDate)].filter(
            (r) => r.majlis === value
          )
        : [];
      if (sortByOffice) {
        filtered.sort((f, s) =>
          f.office === s.office ? 0 : f.office > s.office ? 1 : -1
        );
      } else {
        filtered.sort((f, s) =>
          f.date === s.date ? 0 : dayjs(f.date).isAfter(dayjs(s.date)) ? 1 : -1
        );
      }
      if (value === "All") {
        value = "National";
      }
      reports[value] = filtered;
      return reports;
    },
    { National: [] }
  );

  return (
    <Content>
      <div className="flex justify-start items-center flex-wrap gap-2">
        <Button
          type="primary"
          icon={<LeftOutlined />}
          ghost
          onClick={previousMonth}
        />
        <DatePicker
          style={{ width: 110 }}
          picker="month"
          format="MMM YY"
          disabledDate={(current) => dayjs().isBefore(current)}
          onChange={setSelectedDate}
          clearIcon={false}
          value={selectedDate}
          className="border-primary-500"
        />
        <Button
          type="primary"
          icon={<RightOutlined />}
          ghost
          onClick={nextMonth}
          disabled={asMMYYYY(selectedDate) === asMMYYYY(dayjs())}
        />
        <Button
          type="primary"
          onClick={() => fetchReport(asMMYYYY(selectedDate))}
        >
          Fetch
        </Button>
        <Checkbox
          checked={sortByOffice}
          onChange={(e) => setSortByOffice(e.target.checked)}
        >
          Sort by Office
        </Checkbox>
      </div>
      <br />
      {!activityReports[asMMYYYY(selectedDate)] && (
        <Empty description="Click on Fetch Reports to get the reports for this month.">
          <Button
            type="primary"
            onClick={() => fetchReport(asMMYYYY(selectedDate))}
          >
            Fetch Reports
          </Button>
        </Empty>
      )}
      {activityReports[asMMYYYY(selectedDate)] &&
        activityReports[asMMYYYY(selectedDate)].length > 0 && (
          <Collapse>
            {Object.keys(compiledReports).map((item, index) => (
              <Collapse.Panel
                header={
                  item +
                  (compiledReports[item].length === 1
                    ? " - 1 report"
                    : compiledReports[item].length === 0
                    ? ""
                    : " - " + compiledReports[item].length + " reports")
                }
                key={index}
                disabled={compiledReports[item].length === 0}
              >
                {
                  <List
                    itemLayout="vertical"
                    bordered
                    dataSource={compiledReports[item]}
                    renderItem={(item, index) => (
                      <List.Item className="p-2 md:px-4">
                        <div className="flex justify-between pb-2">
                          <div className="font-bold">
                            {`${item.office + " - "}${item.event} (${
                              item.event_type
                            }${
                              item.halqa !== "All" && item.halqa !== "N/A"
                                ? `, ${item.halqa}`
                                : ""
                            })`}
                            <div className="text-gray-700 text-xs">{`${dayjs(
                              item.date
                            ).format("ddd DD MMM")}`}</div>
                          </div>
                        </div>
                        <div>{`${item.attendance_khuddam} KHU, ${
                          item.attendance_atfal
                        } ATF ${
                          item.comments !== "" ? " |  " + item.comments : ""
                        }`}</div>
                      </List.Item>
                    )}
                  />
                }
              </Collapse.Panel>
            ))}
          </Collapse>
        )}
      {activityReports[asMMYYYY(selectedDate)] &&
        activityReports[asMMYYYY(selectedDate)].length === 0 && (
          <Empty description="No reports found. Click on 'Fetch Reports' to get the latest.">
            <Button
              type="primary"
              onClick={() => fetchReport(asMMYYYY(selectedDate))}
            >
              Fetch Reports
            </Button>
          </Empty>
        )}
      <br />
    </Content>
  );
};

export default ActivitiesViewAllReports;
