import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import dayjs from 'dayjs'

import { Layout, Form, Input, DatePicker, Radio, Button, Select, InputNumber, Checkbox } from 'antd'

import { getUserSession, getHalaqat, getMajalis, getOfficesAndEvents } from '../../../constants/stateAccessors'
import { sendActivityReport } from '../../../actions/'
import { formCommmonProps } from '../../../constants/styles'

const { Content } = Layout

const ActivitySendReport = () => {
  const [form] = Form.useForm()

  const username = useSelector((state) => getUserSession(state).username)
  const userAttributes = useSelector((state) => getUserSession(state).attributes)
  const majalis = useSelector(getMajalis)
  const halaqat = useSelector(getHalaqat)
  const officesAndEvents = useSelector(getOfficesAndEvents)
  const dispatch = useDispatch()

  const [submitting, setSubmitting] = useState(false)

  const reportingAsNationalAmlaMember = () =>
    userAttributes['custom:accessLevel'] === 'National Amla Member' ||
    (userAttributes['custom:accessLevel'] === 'National Amla Member And Qaed' && form.getFieldValue('majlis') === 'All')

  const handleSubmit = (values) => {
    if (!submitting) {
      setSubmitting(true)
      if (values.attendance_khuddam === null) {
        values.attendance_khuddam = 0
      }
      if (values.attendance_atfal === null) {
        values.attendance_atfal = 0
      }
      let total_khuddam_at_date =
        values.halqa === undefined || values.halqa === 'All' ? majalis[values.majlis].khuddam : halaqat[values.majlis][values.halqa].khuddam
      let total_atfal_at_date =
        values.halqa === undefined || values.halqa === 'All' ? majalis[values.majlis].atfal : halaqat[values.majlis][values.halqa].atfal

      let percentage_khuddam = 0
      let percentage_atfal = 0

      if (total_khuddam_at_date !== 0 && values.attendance_khuddam !== 0) {
        percentage_khuddam = (100 * values.attendance_khuddam) / total_khuddam_at_date
      }
      if (total_atfal_at_date !== 0 && values.attendance_atfal !== 0) {
        percentage_atfal = (100 * values.attendance_atfal) / total_atfal_at_date
      }

      let participating_majalis = values.participating_majalis === undefined ? '' : values.participating_majalis.join(',')

      dispatch(
        sendActivityReport({
          halqa: 'N/A',
          reporter: username,
          event_type: 'National',
          comments: '',
          total_khuddam_at_date,
          total_atfal_at_date,
          percentage_khuddam,
          percentage_atfal,
          ...values,
          participating_majalis,
          date: values.date.format('YYYY-MM-DD'),
          clear: () => {
            if (values.clear) {
              form.resetFields()
            }
            setSubmitting(false)
          },
          onError: () => {
            setSubmitting(false)
          },
        })
      )
    }
  }

  return (
    <Content className="ant-layout-content-form-wrapper">
      <Form
        form={form}
        onFinish={handleSubmit}
        {...formCommmonProps}
        requiredMark={false}
        initialValues={{
          majlis: userAttributes['custom:majlis'],
          office:
            reportingAsNationalAmlaMember() ||
            (userAttributes['custom:office'] !== 'All' &&
              userAttributes['custom:accessLevel'] === 'Admin' &&
              userAttributes['custom:majlis'] === 'All')
              ? userAttributes['custom:office'].split(', ')[0]
              : userAttributes['custom:accessLevel'] === 'Nazim'
              ? userAttributes['custom:office'].split(', ')[0]
              : undefined,
        }}
        onFieldsChange={(changedFields) => {
          if (changedFields[0]?.name[0] === 'majlis' && changedFields[0]?.touched) {
            const office =
              reportingAsNationalAmlaMember() ||
              (userAttributes['custom:office'] !== 'All' &&
                userAttributes['custom:accessLevel'] === 'Admin' &&
                form.getFieldValue('majlis') === 'All')
                ? userAttributes['custom:office'].split(', ')[0]
                : userAttributes['custom:accessLevel'] === 'Nazim'
                ? userAttributes['custom:office'].split(', ')[0]
                : undefined
            form.setFieldValue('office', office)
          }
        }}
        validateTrigger={['onChange', 'onBlur', 'onSubmit']}
      >
        <Form.Item
          label={<span>Majlis&nbsp;</span>}
          labelCol={formCommmonProps.labelCol}
          wrapperCol={formCommmonProps.wrapperCol}
          name="majlis"
          rules={[
            {
              required: true,
              message: 'Please select a majlis.',
            },
          ]}
        >
          {userAttributes['custom:accessLevel'] === 'Qaed' || userAttributes['custom:accessLevel'] === 'Nazim' ? (
            <Select className="w-full" placeholder="Select Majlis">
              <Select.Option value={userAttributes['custom:majlis']}>{userAttributes['custom:majlis']}</Select.Option>
            </Select>
          ) : userAttributes['custom:accessLevel'] === 'National Amla Member And Qaed' ? (
            <Select className="w-full" placeholder="Select Majlis">
              <Select.Option value={'All'}>All</Select.Option>
              <Select.Option value={userAttributes['custom:majlis']}>{userAttributes['custom:majlis']}</Select.Option>
            </Select>
          ) : (
            <Select className="w-full" placeholder="Select Majlis">
              {Object.keys(majalis).map((v, i) => (
                <Select.Option key={i} value={v}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(p, c) => p.majlis !== c.majlis}>
          {() =>
            form.getFieldValue('majlis') in halaqat && (
              <Form.Item label={<span>Halqa&nbsp;</span>} name="halqa" initialValue="All">
                <Select className="w-full" placeholder="Select Halqa">
                  {Object.keys(halaqat[form.getFieldValue('majlis')]).map((v) => (
                    <Select.Option key={v} value={v}>
                      {v}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(p, c) => p.majlis !== c.majlis}>
          {() => {
            return (
              <Form.Item
                label={<span>Office&nbsp;</span>}
                name="office"
                rules={[
                  {
                    required: true,
                    message: 'Please select an office.',
                  },
                ]}
              >
                <Select className="w-full" placeholder="Select Office">
                  {reportingAsNationalAmlaMember()
                    ? userAttributes['custom:office'].split(', ').map((v) => (
                        <Select.Option key={v} value={v}>
                          {v}
                        </Select.Option>
                      ))
                    : Object.keys(officesAndEvents).map((v) => (
                        <Select.Option key={v} value={v}>
                          {v}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            )
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(p, c) => p.majlis !== c.majlis}>
          {() =>
            form.getFieldValue(['majlis']) !== 'All' && (
              <Form.Item
                label={<span>Event Scope&nbsp;</span>}
                name="event_type"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue="Local"
              >
                <Radio.Group className="w-full" buttonStyle="solid">
                  <Radio.Button value="National">National</Radio.Button>
                  <Radio.Button value="Regional">Regional</Radio.Button>
                  <Radio.Button value="Local">Local</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )
          }
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(p, c) => p.majlis !== c.majlis || p.event_type !== c.event_type}>
          {() =>
            form.getFieldValue('event_type') === 'Regional' &&
            form.getFieldValue('majlis') !== 'All' && (
              <Form.Item
                label={<span>Participating Majalis&nbsp;</span>}
                name="participating_majalis"
                rules={[
                  {
                    required: true,
                    message: 'Please select all participating majalis in the regional event.',
                  },
                ]}
                normalize={(val) => {
                  if (val.indexOf(form.getFieldValue('majlis')) < 0) {
                    return [form.getFieldValue('majlis'), ...val]
                  }

                  return val
                }}
                initialValue={[form.getFieldValue('majlis')]}
              >
                <Select className="w-full" mode="multiple" placeholder="Select Majlis">
                  {Object.keys(majalis).map((v) => (
                    <Select.Option key={v} value={v}>
                      {v}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          }
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(p, c) => p.office !== c.office}>
          {() =>
            form.getFieldValue('office') !== undefined && (
              <Form.Item
                label={<span>Event&nbsp;</span>}
                extra={
                  (form.getFieldValue('office') === 'Khidmate Khalq' ||
                    form.getFieldValue('office') === 'Waqare Amal' ||
                    form.getFieldValue('event') === 'Other') && <p>Please give more details in comments below.</p>
                }
                name="event"
                rules={[
                  {
                    required: true,
                    message: 'Please enter an event',
                  },
                ]}
              >
                <Select className="w-full" placeholder="Select Event">
                  {form.getFieldValue('office') in officesAndEvents ? (
                    officesAndEvents[form.getFieldValue('office')].events.map((v) => (
                      <Select.Option key={v} value={v}>
                        {v}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option key={'Other'} value={'Other'}>
                      Other
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            )
          }
        </Form.Item>

        <Form.Item
          label={<span>Event Date&nbsp;</span>}
          name="date"
          rules={[
            {
              required: true,
              message: "Please input the event's date!",
            },
          ]}
          initialValue={dayjs()}
        >
          <DatePicker
            onFocus={(e) => (e.target.readOnly = true)}
            allowClear={false}
            format={'DD-MM-YYYY'}
            disabledDate={(current) => {
              return current.isAfter(dayjs(), 'day')
            }}
          />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(p, c) => p.office !== c.office || p.event !== c.event}>
          {() => (
            <Form.Item
              label={<span>Comments&nbsp;</span>}
              name="comments"
              initialValue=""
              rules={[
                {
                  required:
                    form.getFieldValue('office') === 'Khidmate Khalq' ||
                    form.getFieldValue('office') === 'Waqare Amal' ||
                    form.getFieldValue('event') === 'Other',
                  message: 'Please enter the comment',
                },
              ]}
            >
              <Input placeholder="Comments" />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          label={<span className="two-liner-325">No. of Khuddam&nbsp;</span>}
          name="attendance_khuddam"
          rules={[
            {
              type: 'number',
              message: 'Please enter only numbers',
            },
            {
              validator: (_, v) => (v < 0 ? Promise.reject('Uhm, less than zero?') : Promise.resolve()),
            },
          ]}
          initialValue={0}
        >
          <InputNumber placeholder="No. of khuddam" />
        </Form.Item>
        <Form.Item
          label={<span>No. of Atfal&nbsp;</span>}
          name="attendance_atfal"
          rules={[
            {
              type: 'number',
              message: 'Please enter only numbers',
            },
            {
              validator: (_, v) => (v < 0 ? Promise.reject('Uhm, less than zero?') : Promise.resolve()),
            },
          ]}
          initialValue={0}
        >
          <InputNumber placeholder="No. of atfal" />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            sm: {
              span: 14,
              offset: 10,
            },
          }}
          name="clear"
          valuePropName="checked"
          initialValue={true}
        >
          <Checkbox>Clear after submit</Checkbox>
        </Form.Item>
        <Form.Item
          className="pt-2 sm:pt-0"
          wrapperCol={{
            sm: {
              span: 14,
              offset: 10,
            },
          }}
        >
          <Button type="primary" htmlType="submit" disabled={submitting}>
            Send
          </Button>
          <Button type="danger" onClick={() => form.resetFields()} className="mx-1">
            Clear
          </Button>
        </Form.Item>
      </Form>
    </Content>
  )
}

export default ActivitySendReport
