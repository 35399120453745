import React from 'react';
import { Tabs } from 'antd';
import { useSwipeable } from 'react-swipeable';

// const DEFAULT_SWIPE_DISTANCE = 100;

/**
 *  swipeRestriction: "LEFT", "RIGHT", "NONE", "BOTH"
 *  swipeRestrictionDistance: default 50
 *  swipeDistance: default 100
 */
const SwipeableTabs = ({ children, defaultActiveKey, swipeRestrictionDistance = 50, ...rest }) => {
  const [activeKey, setActiveKey] = React.useState(parseInt('' + defaultActiveKey));

    const onChange = (activeKey) => {
    setActiveKey(parseInt(activeKey));
  };

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] > swipeRestrictionDistance && activeKey !== 1) setActiveKey(activeKey - 1);
    },
    onSwipedLeft: (eventData) => {
      if (eventData.initial[0] > swipeRestrictionDistance && activeKey !== children.length) setActiveKey((a) => a + 1);
    },
    delta: 150,
  });

  return (
    <div {...handlers} className='h-full'>
      <Tabs tabBarGutter={8} {...rest} onChange={onChange} defaultActiveKey={'' + defaultActiveKey} activeKey={'' + activeKey}>
        {children}
      </Tabs>
    </div>
  );
};

export default SwipeableTabs;
