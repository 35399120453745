import React from "react";
import { Layout, Tabs } from "antd";
import { useSelector } from "react-redux";
import { getUserSession } from "../../../constants/stateAccessors";

import ActivitiesSendReport from "./SendReport";
import ActivitiesViewReport from "./ViewReport";
import ActivitiesViewAllReports from "./ViewAllReports";
import SwipeableTabs from "../SwipeableTabs";

const Activities = () => {
  const authorizedViewer =
    useSelector((state) => getUserSession(state)).attributes[
      "custom:accessLevel"
    ] === "Admin";

  return (
    <Layout className="h-full">
      <Layout.Header>Activities</Layout.Header>
      <Layout.Content className="px-1 pb-4">
        <SwipeableTabs defaultActiveKey="1">
          <Tabs.TabPane tab="Send Report" key="1">
            <ActivitiesSendReport />
          </Tabs.TabPane>
          <Tabs.TabPane tab="View Reports" key="2">
            <ActivitiesViewReport />
          </Tabs.TabPane>
          {authorizedViewer && (
            <Tabs.TabPane tab="View All Reports" key="3">
              <ActivitiesViewAllReports />
            </Tabs.TabPane>
          )}
        </SwipeableTabs>
      </Layout.Content>
    </Layout>
  );
};

export default Activities;
