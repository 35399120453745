import * as actionTypes from "./actionTypes";

export const refreshLogin = () => ({
  payload: {},
  type: actionTypes.REFRESH_LOGIN
});

export const login = ({ username, password }) => ({
  payload: {
    password,
    username
  },
  type: actionTypes.LOGIN
});

export const loggedIn = req => {
  return {
    payload: { ...req },
    type: actionTypes.LOGGED_IN
  };
};

export const loginError = ({ username, errorMessage }) => ({
  payload: {
    username,
    errorMessage
  },
  type: actionTypes.LOGIN_ERROR
});

export const logout = () => ({ payload: {}, type: actionTypes.LOGOUT });

export const loggedOut = () => ({ payload: {}, type: actionTypes.LOGGED_OUT });

export const loggingIn = () => ({ payload: {}, type: actionTypes.LOGGING_IN });

export const sessionExpired = () => ({ payload: {}, type: actionTypes.SESSION_EXPIRED });

export const createAdmin = ({ username, password, attributes }) => ({
  payload: {
    username,
    password,
    attributes
  },
  type: actionTypes.CREATE_ADMIN
});

export const createUser = ({ username, password, attributes, clear }) => ({
  payload: {
    username,
    password,
    attributes,
    clear
  },
  type: actionTypes.CREATE_USER
});

export const userCreated = userDetails => ({
  payload: { ...userDetails },
  type: actionTypes.USER_CREATED
});

export const getUsers = token => ({
  payload: {
    token: token
  },
  type: actionTypes.GET_USERS
});

export const signupError = err => ({
  payload: {
    err
  },
  type: actionTypes.SIGNUP_ERROR
});

export const signUpSuccess = req => ({
  payload: {
    ...req
  },
  type: actionTypes.SIGNUP_SUCCESS
});

export const loadUsers = users => ({ payload: { users: users }, type: actionTypes.LOAD_USERS });

export const updateUser = ({ username, attributes, clear }) => ({
  payload: {
    username,
    attributes,
    clear
  },
  type: actionTypes.UPDATE_USER
});


export const updateUserSuccess = req => ({
  payload: {
    ...req
  },
  type: actionTypes.UPDATE_USER_SUCCESS
});