import { Layout } from 'antd'
import React, { Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, HashRouter as Router, Switch } from 'react-router-dom'
import { Swipeable } from 'react-swipeable'
import { getUserSession } from '../constants/stateAccessors'
import Login from './Login'
import Activities from './routes/Activities'
import Sidebar from './routes/Sidebar'
import AtfalPresence from './routes/AtfalPresence'

const NamazBaajamaat = lazy(() => import('./routes/NamazBaajamaat'))
const Checklist = lazy(() => import('./routes/Checklist'))
const DoorToDoor = lazy(() => import('./routes/DoorToDoor'))
const MuhtamimReport = lazy(() => import('./routes/MuhtamimReport'))
const Admin = lazy(() => import('./routes/Admin'))
const OfficeBearers = lazy(() => import('./routes/OfficeBearers'))
const Ijtema = lazy(() => import('./routes/Ijtema'))
const PrivacyPolicy = lazy(() => import('./routes/PrivacyPolicy'))

const { Content } = Layout

class AppRouter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      width: '255',
      smallScreen: false,
    }
  }

  toggle() {
    this.setState({
      collapsed: !this.state.collapsed,
      smallScreen: true,
      width: '100%',
    })
  }

  onBreakpoint(broken) {
    this.setState({
      collapsed: false,
      smallScreen: broken,
      width: broken ? '100%' : '255',
    })
  }

  render() {
    const { userAttributes, isLoggedIn } = this.props

    const Fallback = () => (
      <div className="absolute font-bold text-lg text-primary-500 inset-0 flex justify-center items-center">
        <span>Loading...</span>
      </div>
    )

    return (
      <Router>
        <Route
          path="/privacy-policy"
          render={() => (
            <Suspense fallback={<Fallback />}>
              <PrivacyPolicy />
            </Suspense>
          )}
        />
        {isLoggedIn && (
          <Layout hasSider={true} className="h-full">
            <Sidebar
              onBreakpoint={this.onBreakpoint.bind(this)}
              toggle={this.toggle.bind(this)}
              width={this.state.width}
              collapsed={this.state.collapsed}
              smallScreen={this.state.smallScreen}
              collapse={() => this.setState({ collapsed: true })}
            />
            <Content
              className={`overflow-auto main-content ${!this.state.smallScreen || this.state.collapsed ? '' : 'main-content-sider-collapsed'}`}
            >
              <Swipeable
                onSwipedRight={(e) => {
                  if (e.initial[0] < 50 && this.state.smallScreen) {
                    this.toggle()
                  }
                }}
                className="h-full relative"
              >
                <Switch>
                  <Route path="/activities" exact={true} render={() => <Activities />} />
                  <Route
                    path="/spiritual-reform"
                    exact={true}
                    render={() => (
                      <Suspense fallback={<Fallback />}>
                        <Checklist />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/namaz"
                    exact={true}
                    render={() => (
                      <Suspense fallback={<Fallback />}>
                        <NamazBaajamaat />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/doortodoor"
                    render={() => (
                      <Suspense fallback={<Fallback />}>
                        <DoorToDoor />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/atfal-presence"
                    render={() => (
                      <Suspense fallback={<Fallback />}>
                        <AtfalPresence />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/officebearers"
                    render={() => (
                      <Suspense fallback={<Fallback />}>
                        <OfficeBearers />
                      </Suspense>
                    )}
                  />
                  {(userAttributes['custom:accessLevel'] === 'Admin' || userAttributes['custom:accessLevel'].indexOf('National Amla') > -1) && (
                    <Route
                      path="/muhtamim"
                      render={() => (
                        <Suspense fallback={<Fallback />}>
                          <MuhtamimReport />
                        </Suspense>
                      )}
                    />
                  )}
                  {userAttributes['custom:accessLevel'] === 'Admin' && (
                    <Route
                      path="/admin"
                      render={() => (
                        <Suspense fallback={<Fallback />}>
                          <Admin />
                        </Suspense>
                      )}
                    />
                  )}

                  <Route
                    path="/ijtema"
                    render={() => (
                      <Suspense fallback={<Fallback />}>
                        <Ijtema />
                      </Suspense>
                    )}
                  />

                  <Route path="/" render={() => <Redirect push to="/spiritual-reform" />} />
                </Switch>
              </Swipeable>
            </Content>
          </Layout>
        )}
        {!isLoggedIn && <Route path="/" exact={true} render={() => <Login />} />}
        {!isLoggedIn && <Route path="*" render={() => <Redirect push to="/" />} />}
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  return { userAttributes: getUserSession(state).attributes, isLoggedIn: getUserSession(state).loggedIn }
}

export default connect(mapStateToProps)(AppRouter)
