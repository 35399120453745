import { createReducer } from "redux-create-reducer";
import * as actionTypes from "./actionTypes";
import { notification, message } from "antd";

// Note: actual state hierarchy = state => [reducer name, or given name] => whatever is defined below

const initialState = {
  loggedIn: false,
  username: "",
  sessionExpired: false,
  loggingIn: false,
  attributes: {},
  backendUrl: ""
};

export default createReducer(initialState, {
  [actionTypes.LOGGING_IN](state, action) {
    return {
      ...state,
      ...action.payload,
      loggingIn: message.loading("Logging in...", 0)
    };
  },
  [actionTypes.LOGGED_IN](state, action) {
    if (state.loggingIn !== false) {
      state.loggingIn();
    }
    return {
      ...state,
      ...action.payload,
      attributes: { ...action.payload.attributes },
      loggedIn: true,
      loggingIn: false
    };
  },
  // eslint-disable-next-line no-unused-vars
  [actionTypes.LOGGED_OUT](state, action) {
    return {
      ...state,
      loggedIn: false,
      username: "",
      sessionExpired: false,
      loggingIn: false,
      attributes: {},
      backendUrl: ""
    };
  },
  [actionTypes.LOGIN_ERROR](state, action) {
    notification["warning"]({ message: "Login Failure", description: action.payload.errorMessage, duration: 3 });
    if (state.loggingIn !== false) {
      state.loggingIn();
    }
    return {
      ...state,
      ...action.payload,
      loggedIn: false,
      sessionExpired: false,
      username: "",
      loggingIn: false
    };
  },
  // eslint-disable-next-line no-unused-vars
  [actionTypes.SESSION_EXPIRED](state, action) {
    return {
      ...state,
      loggedIn: false,
      sessionExpired: false,
      username: "",
      loggingIn: false,
      attributes: {},
      backendUrl: ""
    };
  },
  [actionTypes.SIGNUP_ERROR](state, action) {
    notification["warning"]({ message: "Signup Error", description: action.payload.err, duration: 3 });
    return {
      ...state
    };
  },
  [actionTypes.SIGNUP_SUCCESS](state, action) {
    notification["success"]({
      message: "User Creation Successful",
      description: "Account for " + action.payload.username + " created.",
      duration: 3
    });
    return {
      ...state
    };
  }
});
