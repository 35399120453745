import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Button, Layout } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import KhuddamLogo from './KhuddamLogo';
import { getAWSCognitorSession } from '../awsCognitor/awsCognitor';
import { login } from '../awsCognitor/awsActions';
import background from '../assets/bg_desktop_min.jpg';
import backgroundMobile from '../assets/bg_mobile.jpg';
import './login.less';

const FormItem = Form.Item;

const Login = () => {
  const loggingIn = useSelector((state) => getAWSCognitorSession(state).loggingIn);
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    dispatch(login({ username: values.username, password: values.password }));
  };

  const [form] = Form.useForm();

  return (
    <Layout className='h-full'>
      <Layout.Content>
        <Row className='h-full relative' style={{minHeight: 425}}>
          <div className='absolute top-0 left-0 right-0 md:right-1/3 bottom-2/5 md:bottom-0'>
            <picture>
              <source srcSet={background} media='(min-width: 768px)' />
              <img src={backgroundMobile} alt='' className='w-full h-full object-cover' />
            </picture>
          </div>
          <Col xs={24} md={14}>
            <Row className='h-full' type='flex' justify='center' align='middle'>
              <Col>
                <div className='fit-logo'>
                  <KhuddamLogo />
                </div>
                <span className='simple'>Khuddam Ul Ahmadiyya</span>
                <span className='simple'>Mauritius</span>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={10} className='login-background'>
            <Row className='h-full' type='flex' justify='center' align='middle'>
              <Col>
                <Form form={form} onFinish={handleSubmit}>
                  <FormItem
                    name='username'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your username!',
                      },
                    ]}
                  >
                    <Input size='large' prefix={<UserOutlined className='text-primary-500' />} placeholder='Username' />
                  </FormItem>
                  <FormItem
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input size='large' prefix={<LockOutlined className='text-primary-500' />} type='password' placeholder='Password' />
                  </FormItem>
                  <FormItem className='mb-0'>
                    <Button className='w-full m-0' type='primary' htmlType='submit' size='large' loading={loggingIn !== false}>
                      Log in
                    </Button>
                  </FormItem>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default Login;
