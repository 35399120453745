import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, DatePicker, Button, Empty, List, Popconfirm } from "antd";
import { LeftOutlined, RightOutlined, DeleteFilled } from "@ant-design/icons";
import { getActivityReports, deleteActivityReport } from "../../../actions/";
import {
  accessActivityReports,
  getUserSession,
} from "../../../constants/stateAccessors";
import dayjs from "dayjs";

const { Content } = Layout;

const ActivitiesViewReport = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const activityReports = useSelector((state) => accessActivityReports(state));
  const userSession = useSelector((state) => getUserSession(state));

  const dispatch = useDispatch();

  const asMMYYYY = (date) => date.format("MM-YYYY");

  const fetchReport = (monthYear) => {
    dispatch(getActivityReports({ monthYear }));
  };

  const confirmDeleteReport = (request) => {
    dispatch(deleteActivityReport(request));
  };

  const previousMonth = () => {
    setSelectedDate(selectedDate.clone().subtract(1, "months")); // use clone to set a new object -> setState won't know the object change
  };

  const nextMonth = () => {
    if (asMMYYYY(selectedDate) !== asMMYYYY(dayjs())) {
      setSelectedDate(selectedDate.clone().add(1, "months")); // use clone to set a new object -> setState won't know the object change
    }
  };

  return (
    <Content>
      <div className="flex justify-start items-center flex-wrap gap-2">
        <Button
          type="primary"
          icon={<LeftOutlined />}
          ghost
          onClick={previousMonth}
        />
        <DatePicker
          style={{ width: 110 }}
          picker="month"
          format="MMM YY"
          disabledDate={(current) => dayjs().isBefore(current)}
          onChange={setSelectedDate}
          clearIcon={false}
          value={selectedDate}
          className="border-primary-500"
        />
        <Button
          type="primary"
          icon={<RightOutlined />}
          ghost
          onClick={nextMonth}
          disabled={asMMYYYY(selectedDate) === asMMYYYY(dayjs())}
        />
        <Button
          type="primary"
          onClick={() => fetchReport(asMMYYYY(selectedDate))}
        >
          Fetch
        </Button>
      </div>
      <br />
      {!activityReports[asMMYYYY(selectedDate)] && (
        <Empty description="Click on Fetch Reports to get the reports for this month.">
          <Button
            type="primary"
            onClick={() => fetchReport(asMMYYYY(selectedDate))}
          >
            Fetch Reports
          </Button>
        </Empty>
      )}
      {activityReports[asMMYYYY(selectedDate)] &&
        activityReports[asMMYYYY(selectedDate)].length > 0 && (
          <List
            header={
              <div className="font-bold text-center text-lg text-primary-500">
                Activity Reports
              </div>
            }
            itemLayout="vertical"
            bordered
            dataSource={activityReports[asMMYYYY(selectedDate)].sort((f, s) =>
              f.date === s.date
                ? 0
                : dayjs(f.date).isAfter(dayjs(s.date))
                ? 1
                : -1
            )}
            renderItem={(item, index) =>
              item.reporter === userSession.username ? (
                <List.Item className="p-2 md:px-4">
                  <div className="flex justify-between pb-2">
                    <div className="font-bold">
                      {`${item.office + " - "}${item.event} (${
                        item.event_type
                      }${
                        item.halqa !== "All" && item.halqa !== "N/A"
                          ? `, ${item.halqa}`
                          : ""
                      })`}
                      <div className="text-gray-700 text-xs">{`${dayjs(
                        item.date
                      ).format("ddd DD MMM")}`}</div>
                    </div>
                    <Popconfirm
                      placement="left"
                      title={"Are you sure you want to delete this report?"}
                      onConfirm={() =>
                        confirmDeleteReport({
                          id: item.id,
                          index: index,
                          monthYear: asMMYYYY(selectedDate),
                        })
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link" size="middle">
                        <DeleteFilled />
                      </Button>
                    </Popconfirm>
                  </div>
                  <div>{`${item.attendance_khuddam} KHU, ${
                    item.attendance_atfal
                  } ATF ${
                    item.comments !== "" ? " |  " + item.comments : ""
                  }`}</div>
                </List.Item>
              ) : (
                <List.Item className="p-2 md:px-4">
                  <div className="flex justify-between pb-2">
                    <div className="font-bold">
                      {`${item.office + " - "}${item.event} (${
                        item.event_type
                      }${
                        item.halqa !== "All" && item.halqa !== "N/A"
                          ? `, ${item.halqa}`
                          : ""
                      })`}
                      <div className="text-gray-700 text-xs">{`${
                        item.majlis === "All" ? "" : item.majlis + " - "
                      }${dayjs(item.date).format("ddd DD MMM")}`}</div>
                    </div>
                  </div>
                  <div>{`${item.attendance_khuddam} KHU, ${
                    item.attendance_atfal
                  } ATF ${
                    item.comments !== "" ? " |  " + item.comments : ""
                  }`}</div>
                </List.Item>
              )
            }
          />
        )}
      {activityReports[asMMYYYY(selectedDate)] &&
        activityReports[asMMYYYY(selectedDate)].length === 0 && (
          <Empty description="No reports found. Click on 'Fetch Reports' to get the latest.">
            <Button
              type="primary"
              onClick={() => fetchReport(asMMYYYY(selectedDate))}
            >
              Fetch Reports
            </Button>
          </Empty>
        )}
      <br />
    </Content>
  );
};

export default ActivitiesViewReport;
