export const formCommmonProps = {
  scrollToFirstError: true,
  layout: 'horizontal',
  colon: false,
  labelAlign: 'left',
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 10,
    },
    md: {
      span: 8,
      offset: 2,
    },
    xl: {
      span: 6,
      offset: 4,
    },
    xxl: {
      span: 4,
      offset: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
    md: {
      span: 12,
    },
    xl: {
      span: 10,
    },
    xxl: {
      span: 8,
    },
  },
}

export const iconStyle = {
  theme: 'twoTone',
  twoToneColor: '#0a234b',
}
