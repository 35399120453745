import React from 'react';

const KhuddamLogo = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468.000000 528.000000" preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,528.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
    <path d="M2810 4955 c-7 -8 -18 -15 -25 -15 -17 0 -65 -57 -65 -78 0 -19 38
-100 51 -108 11 -7 12 -44 1 -44 -5 0 -27 9 -50 20 -23 11 -52 25 -65 30 -57
25 -111 68 -118 93 -4 18 -12 22 -40 21 -18 -1 -50 -7 -69 -14 -19 -7 -42 -15
-50 -17 -10 -3 -2 -15 28 -39 41 -35 42 -38 42 -94 0 -32 -5 -62 -10 -65 -6
-4 -16 -21 -24 -38 -14 -36 -40 -58 -166 -149 -123 -89 -195 -178 -188 -237 3
-22 8 -26 41 -29 32 -3 49 -14 103 -67 76 -77 85 -114 25 -105 -62 8 -141 -50
-141 -103 0 -42 -27 -79 -74 -103 -37 -18 -42 -25 -49 -70 -16 -93 -28 -129
-57 -164 l-28 -34 44 -57 c50 -66 54 -79 19 -79 -38 0 -116 -29 -153 -58 -19
-15 -45 -43 -59 -65 -32 -51 -51 -57 -79 -24 -27 31 -87 37 -120 12 -10 -8
-26 -15 -35 -15 -9 0 -36 -11 -60 -25 -24 -14 -50 -27 -56 -30 -29 -12 -39
-16 -59 -28 -12 -7 -30 -19 -39 -27 -13 -11 -55 -16 -144 -20 -126 -5 -126 -5
-129 -30 -2 -14 6 -46 17 -71 l21 -46 132 -7 c182 -9 305 -21 353 -35 22 -6
58 -11 80 -11 22 0 51 -7 65 -15 14 -8 39 -14 57 -15 18 0 47 -6 65 -14 59
-25 133 -46 158 -46 15 0 31 -5 38 -12 7 -7 38 -15 70 -19 31 -4 82 -11 112
-17 198 -39 322 -49 405 -32 33 7 83 16 110 20 28 4 65 14 83 21 l33 14 -3 70
-3 70 -32 3 c-18 2 -43 -4 -55 -12 -32 -23 -471 -23 -525 -1 -19 8 -45 15 -57
15 -29 0 -155 30 -191 45 -16 8 -43 16 -60 18 -16 3 -43 9 -60 15 -70 25 -139
42 -170 42 -18 0 -49 7 -68 15 -20 8 -65 15 -100 15 -35 0 -67 3 -70 7 -11 11
35 53 58 53 12 0 30 7 41 15 33 25 81 18 141 -22 l56 -37 20 30 c12 16 44 52
72 80 64 65 123 83 210 66 31 -7 61 -9 66 -6 13 8 11 42 -5 72 -33 64 -46 106
-46 145 0 43 21 120 46 169 8 14 14 34 14 44 0 31 69 160 102 193 35 34 72 40
104 16 23 -18 51 -19 56 -3 3 7 1 31 -4 53 -4 22 -12 62 -17 89 -7 43 -17 57
-80 117 -88 84 -90 99 -20 160 28 24 58 47 68 51 37 15 166 125 185 158 15 24
23 59 26 115 4 72 7 80 26 83 11 2 27 -4 34 -13 7 -8 20 -15 30 -15 10 0 23
-7 30 -15 7 -8 23 -15 36 -15 13 0 24 -4 24 -9 0 -5 10 -13 23 -16 12 -4 43
-28 69 -52 29 -29 52 -44 62 -40 22 9 30 68 16 124 -36 149 -32 168 35 179 62
9 162 -1 183 -18 9 -7 49 -28 87 -47 39 -18 77 -37 85 -42 8 -5 25 -13 36 -18
12 -5 39 -31 60 -57 l39 -47 7 -151 c8 -170 19 -251 36 -268 7 -7 12 -21 12
-33 0 -12 7 -30 15 -41 8 -10 15 -25 15 -32 0 -29 181 -242 205 -242 3 0 21
-11 38 -24 18 -14 43 -29 57 -35 14 -6 32 -15 40 -20 8 -5 28 -13 44 -17 21
-6 32 -18 37 -38 5 -16 19 -41 32 -55 39 -42 45 -59 34 -104 -5 -23 -16 -54
-23 -69 -8 -14 -14 -31 -14 -38 0 -6 -16 -35 -35 -64 -19 -29 -35 -58 -35 -63
0 -19 144 -158 181 -174 28 -13 39 -27 53 -65 32 -87 38 -97 140 -220 47 -57
86 -107 86 -112 0 -5 7 -15 15 -22 8 -7 15 -26 15 -41 0 -23 -6 -32 -27 -40
-16 -6 -43 -22 -60 -35 -37 -28 -43 -65 -19 -112 7 -15 19 -62 26 -104 19
-112 8 -142 -97 -264 -47 -54 -89 -108 -94 -119 -5 -11 -13 -27 -17 -35 -5 -8
-15 -26 -23 -40 -9 -17 -14 -64 -14 -150 -1 -184 -4 -189 -180 -322 -117 -88
-151 -108 -189 -113 -25 -4 -46 -9 -46 -12 0 -14 45 -65 69 -77 23 -13 29 -12
48 2 12 10 30 31 40 49 11 18 30 41 44 51 37 29 136 82 153 82 8 0 19 6 23 13
4 6 17 17 28 23 11 6 25 21 31 33 7 13 17 28 23 34 6 6 11 29 12 52 0 63 33
268 47 292 7 12 12 30 12 41 0 11 7 22 15 26 8 3 15 10 15 17 0 11 53 84 76
104 26 23 104 156 104 177 0 13 6 55 14 94 11 59 11 75 0 95 -22 39 -16 66 22
104 48 48 78 117 70 161 -7 34 -63 113 -125 174 -105 104 -161 226 -161 351 0
64 -12 80 -40 54 -29 -26 -88 -22 -119 9 -21 21 -26 35 -24 71 1 38 8 52 45
90 43 46 67 87 67 115 1 8 -17 34 -39 58 -26 28 -45 62 -56 98 -14 48 -21 57
-52 71 -21 8 -49 22 -64 29 -14 8 -35 14 -46 14 -12 0 -24 5 -27 10 -4 6 -21
16 -38 24 -35 14 -97 71 -141 128 -38 50 -96 168 -96 196 0 13 -7 37 -17 55
-13 27 -16 67 -17 242 -2 200 -3 210 -21 214 -11 2 -29 10 -40 17 -11 8 -28
14 -39 14 -10 0 -41 14 -68 30 -26 17 -52 30 -57 30 -5 0 -11 7 -15 15 -3 8
-12 15 -21 15 -8 0 -34 14 -57 30 -26 19 -53 30 -75 30 -18 0 -50 7 -70 15
-22 9 -68 15 -123 15 -66 0 -91 -4 -100 -15z"/>
    <path d="M885 3119 c-22 -4 -67 -8 -100 -9 -33 0 -71 -7 -85 -15 -14 -8 -38
-14 -55 -15 -27 0 -70 -11 -160 -42 -16 -5 -41 -12 -55 -15 -49 -11 -90 -70
-60 -88 6 -3 10 -18 10 -33 0 -15 7 -36 15 -46 8 -11 15 -29 15 -40 0 -12 7
-43 16 -71 51 -152 55 -158 110 -131 19 9 45 16 57 16 13 0 32 7 43 15 10 8
39 15 64 15 25 0 70 7 100 15 30 8 78 15 105 15 28 0 74 3 102 6 61 8 71 24
50 75 -27 64 -39 104 -46 149 -4 25 -12 54 -18 65 -20 38 -43 96 -43 109 0 24
-24 33 -65 25z"/>
    <path d="M1128 2853 c-24 -4 -28 -9 -28 -39 0 -35 30 -101 52 -116 7 -4 73 -8
146 -8 73 0 142 -5 153 -10 10 -6 56 -13 101 -17 46 -3 95 -12 110 -19 15 -8
37 -14 50 -14 24 0 99 -22 156 -46 18 -8 47 -14 65 -14 18 0 41 -7 51 -15 11
-8 34 -15 50 -15 17 0 53 -7 80 -15 27 -8 66 -15 87 -15 21 0 64 -7 96 -15 67
-18 280 -20 333 -4 19 5 58 14 85 19 28 4 68 15 90 23 40 15 40 16 38 64 -2
26 -5 62 -8 80 l-6 31 -52 -20 c-28 -11 -95 -25 -147 -29 -114 -11 -437 -1
-465 14 -11 6 -40 14 -65 19 -75 12 -154 33 -177 46 -12 6 -34 12 -48 12 -14
0 -36 5 -48 12 -25 13 -73 25 -162 39 -33 6 -73 15 -90 21 -45 16 -395 41
-447 31z"/>
    <path d="M855 2539 c-55 -5 -113 -14 -129 -19 -16 -6 -43 -10 -60 -10 -17 -1
-42 -7 -56 -15 -14 -8 -41 -14 -61 -15 -21 0 -44 -8 -57 -20 -20 -19 -20 -23
-7 -53 7 -17 16 -45 20 -60 7 -33 28 -44 58 -30 45 21 135 43 178 43 24 1 55
7 69 15 38 21 589 21 650 0 23 -8 61 -15 85 -15 44 0 181 -32 214 -50 11 -5
35 -10 53 -10 19 0 43 -7 54 -15 10 -8 30 -15 43 -15 13 0 46 -7 75 -16 115
-36 145 -43 222 -48 44 -3 93 -10 108 -16 43 -16 284 -13 342 5 27 8 67 15 90
15 22 0 49 7 60 15 10 8 26 15 35 15 8 0 25 10 37 23 18 20 19 25 7 47 -8 14
-14 42 -15 62 0 45 -13 55 -45 34 -13 -9 -31 -16 -40 -16 -9 0 -26 -6 -38 -14
-40 -24 -184 -39 -317 -32 -159 8 -257 19 -293 34 -16 7 -44 12 -62 12 -19 0
-49 6 -67 14 -43 19 -136 46 -157 46 -9 0 -25 7 -35 15 -11 8 -35 15 -54 15
-19 0 -55 7 -81 15 -135 43 -584 67 -826 44z"/>
    <path d="M830 2285 c-19 -7 -55 -14 -80 -15 -25 0 -63 -7 -85 -15 -22 -8 -51
-15 -65 -15 -14 0 -36 -7 -50 -15 -14 -8 -35 -14 -48 -15 -30 0 -38 -24 -19
-53 8 -13 17 -36 19 -52 5 -44 27 -51 100 -31 34 9 77 22 93 28 17 6 60 13 98
16 l67 4 0 -74 c1 -44 6 -83 15 -98 8 -14 14 -42 15 -62 0 -29 -6 -41 -31 -61
-24 -19 -30 -30 -27 -53 2 -24 13 -34 61 -58 68 -34 72 -51 24 -107 -28 -33
-69 -104 -92 -159 -3 -8 -21 -42 -40 -75 -19 -33 -39 -69 -44 -81 -12 -27 -83
-88 -133 -114 -47 -24 -124 -26 -156 -4 -23 16 -42 12 -42 -9 0 -7 -26 -41
-58 -76 -49 -55 -81 -115 -82 -154 0 -15 39 19 62 53 25 37 72 68 103 69 47 2
125 -82 125 -134 0 -7 6 -20 14 -27 7 -8 16 -23 20 -34 8 -28 66 -89 91 -98
11 -4 42 -12 68 -17 36 -6 62 -21 99 -54 28 -25 55 -45 62 -45 14 0 72 -29
101 -50 12 -8 31 -32 44 -53 13 -21 36 -44 51 -52 58 -30 232 -13 255 25 3 5
31 10 61 10 52 0 58 -3 87 -37 18 -21 49 -55 70 -75 l38 -38 122 0 c87 -1 129
-5 147 -15 14 -8 33 -14 43 -15 9 0 36 -14 60 -31 40 -29 46 -30 112 -26 74 5
171 22 220 39 17 6 48 14 70 18 22 4 72 20 110 34 39 14 77 26 86 26 9 0 22 7
29 15 7 8 20 15 29 15 21 0 82 31 124 63 31 23 42 25 187 29 95 3 167 9 185
17 105 46 128 63 250 181 168 163 245 220 297 220 31 0 68 26 68 48 0 10 -9
42 -21 72 -11 30 -25 70 -30 88 -6 17 -14 32 -19 32 -6 0 -10 8 -10 18 0 10
-27 47 -61 83 -76 81 -86 97 -62 106 10 3 25 13 34 22 8 9 34 31 57 50 23 18
42 38 42 42 0 14 -53 10 -74 -6 -12 -9 -41 -15 -71 -15 -46 0 -55 -4 -87 -37
-20 -21 -43 -52 -51 -69 l-16 -31 45 -33 c52 -37 154 -140 154 -155 0 -6 5
-16 12 -23 17 -17 37 -82 31 -99 -5 -12 -117 -93 -129 -93 -9 0 -154 -112
-154 -119 0 -11 -105 -121 -115 -121 -4 0 -17 -8 -28 -18 -16 -14 -39 -17
-136 -17 -107 0 -121 2 -174 28 -60 29 -87 30 -87 2 0 -23 -155 -175 -177
-175 -11 0 -24 -5 -30 -11 -17 -17 -113 -48 -148 -49 -16 0 -41 -7 -55 -15
-14 -8 -40 -14 -58 -15 -18 0 -45 -8 -59 -17 -39 -25 -237 -26 -273 -1 -16 12
-43 18 -76 18 -37 0 -54 4 -58 15 -4 11 -22 15 -74 15 -90 0 -144 24 -215 95
l-55 55 -74 0 c-53 0 -80 -5 -98 -17 -16 -11 -51 -18 -106 -21 -71 -4 -89 -1
-126 18 -24 12 -47 27 -53 34 -5 7 -30 25 -55 41 -24 15 -67 49 -95 74 -50 46
-77 66 -105 77 -8 3 -29 12 -47 20 -17 8 -40 14 -50 14 -32 0 -63 39 -117 147
-14 28 -31 55 -36 58 -19 12 -10 35 13 35 12 1 33 7 47 15 14 8 37 14 52 15
15 0 30 5 33 11 4 5 17 14 30 19 12 5 32 29 45 54 12 25 26 48 31 52 5 3 9 16
9 28 0 13 7 29 15 36 8 7 15 18 15 26 0 46 141 218 185 226 11 2 21 11 23 21
5 26 -33 99 -78 150 -32 36 -40 53 -40 84 1 21 7 49 14 63 12 21 12 29 0 52
-8 15 -14 50 -14 77 0 44 4 54 37 86 l36 35 101 0 c56 0 146 -7 201 -15 55 -8
127 -15 160 -15 33 0 71 -7 85 -15 14 -8 38 -14 53 -15 39 0 155 -29 190 -46
16 -8 39 -14 52 -14 14 0 39 -6 57 -14 43 -18 159 -46 191 -46 13 0 42 -7 63
-16 58 -24 501 -26 559 -3 22 9 65 24 95 34 82 26 82 26 74 119 -3 45 -9 84
-11 89 -2 4 -18 -3 -36 -15 -48 -34 -99 -58 -125 -58 -12 0 -37 -8 -55 -17
-46 -24 -359 -23 -430 1 -26 9 -69 16 -95 16 -26 1 -58 7 -72 15 -14 8 -36 14
-50 15 -28 0 -112 26 -152 46 -14 8 -42 14 -62 14 -19 0 -50 6 -68 14 -37 16
-121 33 -218 46 -36 5 -81 13 -100 19 -53 17 -555 13 -600 -4z"/>
  </g>
</svg>

export default KhuddamLogo;
