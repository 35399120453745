const configs = {
  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "YOUR_API_GATEWAY_URL"
  },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_aPINEmYxW",
    APP_CLIENT_ID: "1dq5u7ibpmab17ej03hj4mudum",
    IDENTITY_POOL_ID: "ap-southeast-1:1edb958f-86a4-428c-961c-d32aa291676b"
  }
};
export default configs;
/*mka-mauritius-report-webapp-users
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_aPINEmYxW",
    APP_CLIENT_ID: "1dq5u7ibpmab17ej03hj4mudum",
    IDENTITY_POOL_ID: "ap-southeast-1:1edb958f-86a4-428c-961c-d32aa291676b"
  }
*/
