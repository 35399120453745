// import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { combineReducers } from 'redux';
import sessions from './sessionsReducer';
import servicesSaga from './servicesSaga.js';
import { refreshLogin } from './awsActions';

/**
 * Uses combineReducers under the hood to include the AWS required session - enforcing the name for the AWS Session tree
 * @param {reducers} reducers
 */
export const enhancedCombineReducers = (reducers) =>
  combineReducers({
    awsCognitorSession: sessions,
    ...reducers,
  });

export const getAWSCognitorSession = (state) => state.awsCognitorSession;

export const configureAmplify = ({ config, api }) => {
  // window.LOG_LEVEL = "DEBUG";
  let req = {
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    API: { ...api },
  };

  Auth.configure( req.Auth )
  API.configure( req.API )
  // Amplify.configure(req);
};

export const awsCognitorSaga = servicesSaga;

export const refreshSession = (store) => {
  store.dispatch(refreshLogin());
};
