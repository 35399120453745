import { Box } from '@mantine/core'
import { Button, DatePicker, Form, Input, Layout } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendSpiritualReformReport } from '../../../actions'
import { getUserSession } from '../../../constants/stateAccessors'
import { formCommmonProps } from '../../../constants/styles'

const { Content } = Layout

const AtfalPresenceReport = () => {
  const [form] = Form.useForm()

  const username = useSelector((state) => getUserSession(state).username)
  const userAttributes = useSelector((state) => getUserSession(state).attributes)

  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = (values) => {
    setSubmitting(true)

    const report = {
      reporter: username,
      designation: userAttributes['custom:designation'],
      majlis: userAttributes['custom:majlis'],
      name: userAttributes.name,
      week: values.week.week(),
      year: values.week.year(),
      month: values.week.format('MMMM'),
      // monthly: {
      letter: values.letter,
      participation: values.participation,
      // },

      // weekly: {
      monday: values.monday.join(','),
      tuesday: values.tuesday.join(','),
      wednesday: values.wednesday.join(','),
      thursday: values.thursday.join(','),
      friday: values.friday.join(','),
      saturday: values.saturday.join(','),
      sunday: values.sunday.join(','),
      khutba: values.khutba,
      twwh: values.twwh,
      thursdayInWeek: values.week.format('YYYY-MM-DD'),
      comments: values.comments,
      // },

      clear: () => {
        // form.resetFields()
        setSubmitting(false)
      },
      onError: () => {
        setSubmitting(false)
      },
    }
    // setSubmitting(false)
    // console.log(report)
    dispatch(sendSpiritualReformReport(report))
  }

  const formInitialValues = {
    week: 1,
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
    khutba: 'No',
    twwh: 'No',
    letter: 'No',
    participation: '',
    comments: '',
  }

  return (
    <Content>
      <Box fz="sm" fs="italic" bg="gray.2" p="xs" sx={{ border: '1pt dashed #fff' }} mt="lg" mb={{ base: 'md', sm: '2.5rem' }} maw="50rem" mx="auto">
        Please select all atfal who present in the class. If a name is missing, please add it in the comment section.
      </Box>
      <Form
        form={form}
        onFinish={submitting ? undefined : handleSubmit}
        {...formCommmonProps}
        requiredMark={false}
        labelWrap
        initialValues={formInitialValues}
      >
        <Form.Item
          label={<span className="font-bold">Class date&nbsp;</span>}
          name="date"
          rules={[
            {
              required: true,
              message: 'Please select a date.',
            },
          ]}
        >
          <DatePicker
            // format={'[Week] w [of] YYYY'}
            placeholder="Select date"
            // onChange={(date) => {
            //   const thisDay = date.day()
            //   if (thisDay === 0) {
            //     date = date.subtract(3, 'days')
            //   } else {
            //     date = date.add(4 - thisDay, 'days')
            //   }
            //   form.setFieldsValue({ week: date })
            // }}
            disabledDate={(current) => {
              return current.isAfter(dayjs())
            }}
          />
        </Form.Item>

        <Form.Item label={<span className="font-bold">Comments&nbsp;</span>} name="comments">
          <Input.TextArea placeholder="Any comment" />
        </Form.Item>

        <Form.Item
          className="pt-2 sm:pt-0"
          wrapperCol={{
            sm: {
              span: 14,
              offset: 10,
            },
          }}
        >
          <Button type="primary" htmlType="submit" disabled={submitting}>
            Send
          </Button>
        </Form.Item>
      </Form>
    </Content>
  )
}

export default AtfalPresenceReport
